import React, { createContext, useState, useEffect } from 'react';

export const CallsContext = createContext();

export const CallsProvider = ({ children }) => {
    const [calls, setCalls] = useState(0);

    // Optionally, if you need to log calls or perform actions when it changes, use an effect
    useEffect(() => {
        // Any other logic that needs to run when `calls` changes can go here
    }, [calls]);

    return (
        <CallsContext.Provider value={{ calls, setCalls }}>
            {children}
        </CallsContext.Provider>
    );
};
