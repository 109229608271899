// CircleNode.js
import React from 'react';
import { Handle } from 'reactflow';

const CircleNode = () => (
  <div style={{ 
    width: '50px', 
    height: '50px', 
    borderRadius: '50%', 
    background: '#777777', 
    boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.15), 0 2px 4px -1px rgba(0, 0, 0, 0.08)', // Applied complex shadow
  }}>
    {/* Left handle */}
    <Handle 
      type="target" 
      position="left" 
      style={{ borderRadius: '50%' }}
    />
    {/* Right handle */}
    <Handle 
      type="source" 
      position="right" 
      style={{ borderRadius: '50%' }}
    />
    {/* Top handle */}
    <Handle 
      type="target" 
      position="top" 
      id="input1"
      style={{ borderRadius: '50%' }}
    />
    {/* Bottom handle */}
    <Handle 
      type="target" 
      position="bottom" 
      id="input2"
      style={{ borderRadius: '50%' }}
    />
  </div>
);

export default CircleNode;