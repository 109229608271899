import { useState } from 'react';

export function useFieldValidation() {
    const [nameError, setNameError] = useState('');

    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [hasLength, setHasLength] = useState(false);
    const [hasLowerCase, setHasLowerCase] = useState(false);
    const [hasUpperCase, setHasUpperCase] = useState(false);
    const [hasDigit, setHasDigit] = useState(false);
    const [hasSpecialChar, setHasSpecialChar] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [isNameValid, setIsNameValid] = useState(false);
    const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState(false);
    const [emailError, setEmailError] = useState('');
    const allowedDomains = [
        'drsulaimanalhabib.com',
        'cloudsolutions.com.sa',
        'seu.edu.sa',
        'ksu.edu.sa',
        'mu.edu.sa',
        'iau.edu.sa',
        'aou.edu.sa',
        'dah.edu.sa',
        'bu.edu.sa',
        'kfu.edu.sa',
        'saudigerman.com',
        'dallah-health.com',
        'dsah.sa',
        'abeergroup.com',
        'alhammadi.med.sa',
        'moh.gov.sa',
        'ngha.med.sa',
        'kfmc.med.sa',
        'psmmc.med.sa',
        'fahd.med.sa',
        'fmc.med.sa',
        'fakeeh.care',
        'gmail.com'
    ];

    const validateName = (name) => {
        if (name.length < 5) {
            setNameError('Name should be at least 5 characters');
            setIsNameValid(false);
        } else {
            setNameError('');
            setIsNameValid(true);
        }
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
        const domain = (email.split('@')[1] || '').trim().toLowerCase();

        if (email.length === 0) {
            setEmailError('');
            setIsEmailValid(false);
        } else if (!emailRegex.test(email)) {
            setEmailError('Invalid email format');
            setIsEmailValid(false);
        } else if (!allowedDomains.includes(domain)) {
            setEmailError('Your email domain is not allowed currently.');
            setIsEmailValid(false);
        } else {
            setEmailError('');
            setIsEmailValid(true);
        }
    };

    const validatePassword = (password) => {
        const lengthValid = password.length >= 6;
        const lowerCaseValid = /[a-z]/.test(password);
        const upperCaseValid = /[A-Z]/.test(password);
        const digitValid = /\d/.test(password);
        const specialCharValid = /\W|_/.test(password);

        setHasLength(lengthValid);
        setHasLowerCase(lowerCaseValid);
        setHasUpperCase(upperCaseValid);
        setHasDigit(digitValid);
        setHasSpecialChar(specialCharValid);

        const isPasswordValid = lengthValid && lowerCaseValid && upperCaseValid && digitValid && specialCharValid;
        setIsPasswordValid(isPasswordValid);

    };

    const validateConfirmPassword = (password, confirmPassword) => {
        const isConfirmValid = password === confirmPassword;
        setConfirmPasswordError(isConfirmValid ? '' : 'Passwords do not match');
        setIsConfirmPasswordValid(isConfirmValid);
    };


    const isFormValid = () => {
        return isNameValid && isEmailValid && isPasswordValid && isConfirmPasswordValid;
    };

    const isFormValid2 = () => {
        return isEmailValid && isPasswordValid;
    };


    return {
        nameError,

        passwordError,
        confirmPasswordError,
        validateName,

        validatePassword,
        validateConfirmPassword,
        isFormValid,
        hasLength,
        hasLowerCase,
        hasUpperCase,
        hasDigit,
        hasSpecialChar,
        setConfirmPasswordError,
        isEmailValid,
        validateEmail,
        isPasswordValid,
        isNameValid,
        isConfirmPasswordValid,
        emailError,
        isFormValid2
    };
}