// Slides.js
import React from 'react';


export const slidesData = [
    { id: 1, image: `${process.env.PUBLIC_URL}/slides/slide1.png`, description: 'Image 1 Description' },
    { id: 2, image: `${process.env.PUBLIC_URL}/slides/slide2.png`, description: 'Image 2 Description' },
    { id: 3, image: `${process.env.PUBLIC_URL}/slides/slide3.png`, description: 'Image 3 Description' },
];
export const SlidesContext = React.createContext({
    currentSlide: 0,
    setCurrentSlide: () => { },
    nextSlide: () => { },
    prevSlide: () => { },
});
