import React from 'react';
import { Handle, Position } from 'reactflow';
import './fishCss.css';

const fishHandleSide = {
    top: '50%',
    transform: 'translateY(-50%)',
    right: '-3px',
    width: '6px',
    height: '10px',
    borderRadius: '2px',
    background: '#778899',
};

const fishHandleTop = {
    top: '-3px',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '10px',
    height: '6px',
    borderRadius: '2px',
    background: '#778899',
};

const fishHandleBottom = {
    bottom: '-3px',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '10px',
    height: '6px',
    borderRadius: '2px',
    background: '#778899',
};

export const Equipment = () => (
    <div className="custom-node__body react-flow__node-custom">
    Equipment
    <Handle type="target" position={Position.Top} style={fishHandleTop} />
    <Handle type="source" position={Position.Bottom} style={fishHandleBottom} />
  </div>
);

export const Environment = () => (
    <div className="custom-node__body react-flow__node-custom">
    Environment
    <Handle type="target" position={Position.Top} style={fishHandleTop} />
    <Handle type="source" position={Position.Bottom} style={fishHandleBottom} />
  </div>
);

export const Communication = () => (
    <div className="custom-node__body react-flow__node-custom">
    Communication
    <Handle type="target" position={Position.Bottom} style={fishHandleBottom} />
    <Handle type="source" position={Position.Top} style={fishHandleTop} />
  </div>
);

// Patient Factors
export const PatientFactors = () => (
    <div className="custom-node__body react-flow__node-custom">
      Patient Factors
      <Handle type="target" position={Position.Top} style={fishHandleTop} />
      <Handle type="source" position={Position.Bottom} style={fishHandleBottom} />
    </div>
  );
  
  // Staff Factors
  export const StaffFactors = () => (
    <div className="custom-node__body react-flow__node-custom">
      Staff Factors
      <Handle type="target" position={Position.Top} style={fishHandleTop} />
      <Handle type="source" position={Position.Bottom} style={fishHandleBottom} />
    </div>
  );
  
  // Task Factors
  export const TaskFactors = () => (
    <div className="custom-node__body react-flow__node-custom">
      Task Factors
      <Handle type="target" position={Position.Top} style={fishHandleTop} />
      <Handle type="source" position={Position.Bottom} style={fishHandleBottom} />
    </div>
  );
  
  // Education & Training
  export const EducationTraining = () => (
    <div className="custom-node__body react-flow__node-custom">
      Education & Training
      <Handle type="target" position={Position.Bottom} style={fishHandleBottom} />
      <Handle type="source" position={Position.Top} style={fishHandleTop} />
    </div>
  );
  
  // Team Factors
  export const TeamFactors = () => (
    <div className="custom-node__body react-flow__node-custom">
      Team Factors
      <Handle type="target" position={Position.Bottom} style={fishHandleBottom} />
      <Handle type="source" position={Position.Top} style={fishHandleTop} />
    </div>
  );
  
  // Organizational Factors
  export const OrganizationalFactors = () => (
    <div className="custom-node__body react-flow__node-custom">
      Organizational Factors
      <Handle type="target" position={Position.Bottom} style={fishHandleBottom} />
      <Handle type="source" position={Position.Top} style={fishHandleTop} />
    </div>
  );
  
  // Clinical Management
  export const ClinicalManagement = () => (
    <div className="custom-node__body react-flow__node-custom">
      Clinical Management
      <Handle type="target" position={Position.Bottom} style={fishHandleBottom} />
      <Handle type="source" position={Position.Top} style={fishHandleTop} />
    </div>
  );
  