import React, { useContext, useState, useEffect } from 'react';
import themes from '../Components/cases/cases_with_scenarios.json';
import { AppContext } from './localAPI/context.js';
import { slidesData } from './misc/Slides.js';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import '../animations/carousel.css'
import { collection, query, where, doc, getDocs, updateDoc } from 'firebase/firestore';
import { auth, db } from '../firebase-config';
import OverviewFlow from '../thefishBoneDiagram.js';
import DraggableMenu from '../Components/fishbone/DraggableMenu';
import { signOut } from 'firebase/auth';




function Themes({ onSelect, onReset }) {
    const { setAgents } = useContext(AppContext); // Access setAgents from the context
    const [selectedTheme, setSelectedTheme] = useState(() => localStorage.getItem('selectedTheme'));
    const { reset, logout2, isGuideVisible, setIsGuideVisible, setShowGuide, showGuide, sessions, isCreateSessionVisible, setIsCreateSessionVisible, setSessions, autosave, setAutoSave, removeSession, loadSession, updateSessionName, addSession } = useContext(AppContext);
    const [isSessionsVisible, setIsSessionsVisible] = useState(false);
    const [isSettingsVisible, setIsSettingsVisible] = useState(false);

    const [sessionName, setSessionName] = useState('');
    const [isEditSessionVisible, setIsEditSessionVisible] = useState(false);
    const [newSessionName, setNewSessionName] = useState('');
    const [isCanvasMenuOpen, setIsCanvasMenuOpen] = useState(false);

    const closeGuide = () => {
        setIsGuideVisible(false);
    };

    useEffect(() => {
        setIsGuideVisible(showGuide);
    }, [showGuide]);


    const guideDecision = async () => {
        setIsGuideVisible(showGuide);
        setShowGuide(false); // Set showGuide to false in context

        // Update showGuide in user preferences in Firestore
        const userId = auth.currentUser?.uid;
        if (userId) {
            const userDocRef = doc(db, "users", userId);
            await updateDoc(userDocRef, {
                'preferences.showGuide': false
            });
        }
    };

    const autosaveDecision = async (value) => {
        setAutoSave(value); // Set autosave to value in context

        // Update autosave in user preferences in Firestore
        const userId = auth.currentUser?.uid;
        if (userId) {
            const userDocRef = doc(db, "users", userId);
            await updateDoc(userDocRef, {
                'preferences.autosave': value
            });
        }
    };

    const openGuide = () => {
        setIsGuideVisible(true);
    };

    useEffect(() => {
        const fetchSessions = async () => {
            const user = auth.currentUser;
            if (!user) return; // Ensure there's a logged-in user

            const sessionsRef = collection(db, "sessions");
            const q = query(sessionsRef, where("userId", "==", user.uid));
            const querySnapshot = await getDocs(q);
            const fetchedSessions = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));

            setSessions(fetchedSessions);
        };

        fetchSessions();
    }, []);

    const toggleSessions = () => {
        setIsSessionsVisible(!isSessionsVisible);
    };

    const toggleSettings = () => {
        setIsSettingsVisible(!isSettingsVisible);
    };

    const handleSelectTheme = (theme) => {
        setSelectedTheme(theme.Theme);
        localStorage.setItem('selectedTheme', theme.Theme); // Persist the selected theme to localStorage
        setAgents(theme.Agents);
        localStorage.setItem('setAgents', JSON.stringify(theme.Agents));
        // Assuming this sets the agents related to the theme
        setIsCreateSessionVisible(true); // Show the modal for session creation
    };

    const [editingSessionId, setEditingSessionId] = useState(null);

    const editSessionName = (sessionId) => {
        // Find the session to edit
        const sessionToEdit = sessions.find(session => session.id === sessionId);
        // Set the new session name to the current name of the session
        setNewSessionName(sessionToEdit.name);
        // Store the ID of the session being edited
        setEditingSessionId(sessionId);
        // Open the edit modal
        setIsEditSessionVisible(true);
    };

    const [isDeleteSessionVisible, setIsDeleteSessionVisible] = useState(false);
    const [deletingSessionId, setDeletingSessionId] = useState(null);

    const confirmRemoveSession = (sessionId) => {
        // Store the ID of the session to delete
        setDeletingSessionId(sessionId);
        // Open the delete modal
        setIsDeleteSessionVisible(true);
    };

const logout = async () => {
    try {
        // Ensure onReset is defined and is a function
        if (typeof onReset === 'function') {
            onReset();
        }
        localStorage.removeItem('recaptchaToken');

        // Call logout2 last to ensure all operations are completed before the user is signed out
        await logout2();
    } catch (error) {
        console.error("Sign out error", error);
    }
};


    return (
        <div className="z-100 flex flex-col h-screen relative">
            <div className="absolute inset-0 flex items-center justify-center opacity-40">
                <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="Logo" className="w-auto h-auto max-w-full max-h-full opacity-15" />
            </div>
            <div className="p-4 text-white bg-gradient-to-r from-gray-900 to-gray-500 flex items-center justify-start">
                <div className="flex items-center mr-4 mx-10">
                    <img src={`${process.env.PUBLIC_URL}/logowhite.png`} alt="Logo" style={{ width: '50px', height: '50px' }} />
                    <div className="ml-2 text-lg font-bold">RCA AI</div>
                </div>
            </div>

            <div className="relative p-1 text-white bg-gray-200 flex w-full items-start justify-start" style={{ zIndex: 1050 }}>
                <div className="flex flex-grow space-x-1">
                    <button onClick={toggleSessions} className="rounded-lg p-2 ml-4 cursor-pointer text-gray-800 hover:bg-gray-800 hover:text-white transition-colors duration-200 text-xs sm:text-sm md:text-md lg:text-lg xl:text-lg">My Sessions</button>
                    <button onClick={toggleSettings} className="rounded-lg p-2 cursor-pointer text-gray-800 hover:bg-gray-800 hover:text-white transition-colors duration-200 text-xs sm:text-sm md:text-md lg:text-lg xl:text-lg">Settings</button>
                    <button className="rounded-lg p-2 cursor-pointer text-gray-800 hover:bg-gray-800 hover:text-white transition-colors duration-200 text-xs sm:text-sm md:text-md lg:text-lg xl:text-lg" onClick={openGuide}>Guide</button>
                </div>
                <button onClick={logout} className="rounded-lg p-2 cursor-pointer text-gray-800 hover:bg-red-700 hover:text-white transition-colors duration-200 text-xs sm:text-sm md:text-md lg:text-lg xl:text-lg">Logout</button>
            </div>
            <div className="relative p-1 text-white bg-gray-900 flex flex-col items-center justify-center" style={{ zIndex: 1050 }}>
                <div className="font-bold text-center text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl">These are the scenarios main theme to select from</div>
            </div>
            <div className="flex flex-wrap justify-center z-10 pt-4">
                {themes.map((theme, index) => {
                    const [firstPart, secondPart] = theme.Theme.split(':');
                    return (
                        <div
                            key={index}
                            onClick={() => setSelectedTheme(theme.Theme)}
                            className={`p-2 cursor-pointer transform transition-transform duration-200 hover:scale-110 ${selectedTheme === theme.Theme ? 'bg-gray-700 text-white' : 'bg-gradient-to-r from-white to-gray-100 shadow'} sm:w-1/6 w-1/3 border border-gray-200 m-1`}
                            style={{ paddingBottom: '1%', paddingTop: '1%', borderRadius: '5% 0 5% 0' }}
                        >
                            <div className="h-full text-sm sm:text-base">
                                <span className="font-bold">{firstPart}:</span>{secondPart}
                                {selectedTheme === theme.Theme &&
                                    <div className="flex justify-center mt-8">
                                        <button onClick={() => handleSelectTheme(theme)} className="p-1 mt-2 text-orange-500 font-bold animate-pulse bg-gray-200 rounded-lg" disabled={!selectedTheme}>Click to Select</button>
                                    </div>
                                }
                            </div>
                        </div>
                    );
                })}
            </div>
            {isGuideVisible && (
                <div className="fixed inset-0 bg-gray-800 bg-opacity-90 flex items-center justify-center" style={{ zIndex: 1053 }}>
                    <div className="menu-bg-1  p-4 w-[70vw] h-[80vh] text-gray-100 overflow-hidden relative transition-transform duration-200 ease-in-out transform translate-x-0 border rounded-xl">
                        <button onClick={closeGuide} className="absolute top-10 right-0 bg-white text-4xl text-gray-600  p-2" style={{ zIndex: 1053 }} aria-label="Close guide">×</button>
                        <Carousel>
                            {slidesData.map((slide) => (
                                <div key={slide.id} className="slide h-full w-full flex flex-col items-center justify-center">
                                    <img src={slide.image} alt="Guide Slide" className="max-h-[80%] object-contain" />
                                    <p className="mt-4 text-center">{slide.description}</p>
                                </div>
                            ))}
                        </Carousel>
                        <button onClick={guideDecision} className="absolute bottom-10 right-10 bg-gray-800 text-white rounded-xl p-2" aria-label="Do not show guide again">Do not show again</button>
                    </div>
                </div>
            )}
            {isSessionsVisible && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-90 flex items-center justify-center " style={{ zIndex: 1051 }}>
                    <div className="bg-gray-800 p-4 w-[90vw] sm:w-[75vw] md:w-[60vw] lg:w-[50vw] xl:w-[40vw] h-[60vh] sm:h-3/4 md:h-2/3 lg:h-1/2 xl:h-1/3 text-gray-100 overflow-hidden relative rounded-xl transition-transform duration-200 ease-in-out transform translate-x-0">
                        <h2 className="text-md sm:text-md md:text-lg lg:text-lg xl:text-xl mb-8 font-bold">Sessions</h2>
                        <hr />
                        <div className="sessions-list overflow-y-auto h-full sm:h-3/4 md:h-2/3 lg:h-1/2 xl:h-1/3">
                            {sessions.map((session, index) => (
                                <div key={session.id} className="session-item border-b border-gray-400 py-4 flex justify-between hover:bg-gray-700">
                                    <span>{session.name}</span>
                                    <div>
                                        <button onClick={() => loadSession(session.id)} className="text-sm sm:text-sm md:text-base lg:text-lg xl:text-lg text-gray-800 bg-white hover:bg-gray-800 hover:text-white rounded-lg shadow px-2 mx-2 transition-colors duration-200">
                                            Load
                                        </button>
                                        <button onClick={() => editSessionName(session.id)} className="text-sm sm:text-sm md:text-base lg:text-lg xl:text-lg text-gray-800 bg-white hover:bg-gray-800 hover:text-white rounded-lg shadow px-2 mx-2 transition-colors duration-200">
                                            Edit
                                        </button>
                                        <button onClick={() => confirmRemoveSession(session.id)} className="text-sm sm:text-sm md:text-base lg:text-lg xl:text-lg text-gray-800 bg-white hover:bg-gray-800 hover:text-white rounded-lg shadow px-2 mx-2 transition-colors duration-200">
                                            Delete
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <button onClick={toggleSessions} className="absolute top-0 right-0 text-md sm:text-lg md:text-lg lg:text-2xl xl:text-2xl text-gray-100 rounded-xl p-2" style={{ zIndex: 1052 }}>x</button>
                    </div>
                </div>
            )}

            {isSettingsVisible && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-90 flex items-center justify-center " style={{ zIndex: 1051 }}>
                    <div className="bg-gray-800 p-4 w-[90vw] sm:w-[75vw] md:w-[60vw] lg:w-[50vw] xl:w-[40vw] text-gray-100 overflow-hidden relative rounded-xl transition-transform duration-200 ease-in-out transform translate-x-0">
                        <h2 className="text-md sm:text-md md:text-lg lg:text-lg xl:text-xl mb-8 font-bold">Settings</h2>
                        <hr />
                        <div className="auto-save-setting flex items-center justify-between my-4 mt-4">
                            <label className="mr-4">Auto Save My Session</label>
                            <label className="switch">
                                <input
                                    type="checkbox"
                                    checked={autosave}
                                    onChange={(e) => {
                                        setAutoSave(e.target.checked);
                                        autosaveDecision(e.target.checked);
                                    }}
                                />
                                <span className={`slider round ${autosave ? 'bg-green-500' : 'bg-red-500'}`}></span>
                            </label>
                        </div>
                        <button onClick={toggleSettings} className="absolute top-0 right-0 text-3xl text-gray-100  rounded-xl p-2" style={{ zIndex: 1052 }}>x</button>
                    </div>
                </div>
            )}

            {isCreateSessionVisible && (
                // Inside the return statement of Themes.js, within the modal component
                <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center" style={{ zIndex: 1050 }}>
                    <div className="bg-white p-6 rounded-lg shadow-lg">
                        <h2 className="text-lg font-bold mb-4">Create Session Name</h2>
                        <input
                            type="text"
                            value={sessionName}
                            onChange={(e) => setSessionName(e.target.value)}
                            placeholder="Session name"
                            className="border p-2 w-full mb-4"
                        />
                        <div className="flex justify-between">
                            <button
                                onClick={() => {
                                    const createSession = async () => {
                                        await addSession(sessionName, selectedTheme);
                                        setIsCreateSessionVisible(false);
                                        onSelect(selectedTheme);
                                    };
                                    createSession();
                                }}
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                            >
                                Proceed
                            </button>

                            <button
                                onClick={() => setIsCreateSessionVisible(false)}
                                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>

            )}
            {isEditSessionVisible && (
                <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center" style={{ zIndex: 1055 }}>
                    <div className="bg-white p-6 rounded-lg shadow-lg">
                        <h2 className="text-lg font-bold mb-4">Rename Session</h2>
                        <input
                            type="text"
                            value={newSessionName}
                            onChange={(e) => setNewSessionName(e.target.value)}
                            placeholder="New session name"
                            className="p-2 w-full mb-4 bg-gray-200"
                        />
                        <div className="flex justify-between">
                            <button
                                onClick={() => {
                                    updateSessionName(editingSessionId, newSessionName); // Update the session name
                                    setIsEditSessionVisible(false);
                                }}
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                            >
                                Proceed
                            </button>
                            <button
                                onClick={() => setIsEditSessionVisible(false)}
                                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {isDeleteSessionVisible && (
                <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center" style={{ zIndex: 1055 }}>
                    <div className="bg-white p-6 rounded-lg shadow-lg">
                        <h2 className="text-lg  mb-4">Are you sure you want to remove the session? This is irreversible.</h2>
                        <div className="flex justify-between">
                            <button
                                onClick={() => {
                                    removeSession(deletingSessionId); // Remove the session
                                    setIsDeleteSessionVisible(false);
                                }}
                                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                            >
                                Yes
                            </button>
                            <button
                                onClick={() => setIsDeleteSessionVisible(false)}
                                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                            >
                                No
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* fishbone menu */}
            <section style={{ zIndex: 1060 }}>
                <button onClick={() => setIsCanvasMenuOpen(!isCanvasMenuOpen)} className={`fixed top-[25vh] left-0 z-50 bg-transparent sm:bg-gray-200 w-8 sm:w-12 h-24 sm:h-36 rounded-r-lg shadow hover:bg-orange-400 active:bg-orange-800 ${isCanvasMenuOpen ? 'hidden' : ''} hover:filter hover:grayscale hover:brightness-150`}>
                    <div className="text-gray-700 transform rotate-90 whitespace-nowrap text-center w-full h-full flex items-center justify-center">
                        <img src="/fishbone.png" alt="Fishbone diagram" className="w-8 sm:w-full transform sm:scale-150" />
                    </div>
                </button>
                <div className={`fixed z-50 h-full overflow-auto transform top-0 left-0 w-full transition-transform duration-200 ease-in-out ${isCanvasMenuOpen ? 'translate-x-0' : '-translate-x-full'}`} style={{ background: 'linear-gradient(to bottom, white, lightgray, white)', display: 'flex', flexDirection: 'column' }}>
                    <OverviewFlow />
                    <button onClick={() => setIsCanvasMenuOpen(false)} className="p-4 absolute top-0 right-0 z-10" aria-label="Close menu">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-8 w-8">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <div className={`fixed z-50 h-full overflow-auto transform top-0 left-0 w-full transition-transform duration-200 ease-in-out ${isCanvasMenuOpen ? 'translate-x-0' : '-translate-x-full'}`} style={{ background: 'linear-gradient(to bottom, white, lightgray, white)', display: 'flex', flexDirection: 'column' }}>
                    <OverviewFlow />
                    <DraggableMenu />
                    <button onClick={() => setIsCanvasMenuOpen(false)} className="p-4 absolute top-0 right-0 z-10" aria-label="Close menu">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-8 w-8">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
            </section>
        </div >
    );
}

export default Themes;