import React, { useState, useEffect } from 'react';
import 'tailwindcss/tailwind.css';
import Chat from './Components/Chat.js';
import Themes from './Components/Themes.js';
import { AppProvider } from './Components/localAPI/context.js';
import { CallsProvider } from './Components/localAPI/calls.js';
import LandingPage from './landingPage.js';
import { BrowserRouter } from 'react-router-dom';
import { auth } from './firebase-config';
import { onAuthStateChanged } from "firebase/auth";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { doc, onSnapshot } from "firebase/firestore";
import { db } from './firebase-config';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ErrorBoundary from './ErrorBoundary';
import ReactFlow, { ReactFlowProvider } from 'reactflow';

function App() {
    const [selectedTheme, setSelectedTheme] = useState(() => {
        return localStorage.getItem('selectedTheme');
    });
    const [isLoggedIn, setIsLoggedIn] = useState(() => localStorage.getItem('isLoggedIn') === 'true');
    const [isLoggedInReal, setIsLoggedInReal] = useState(false);
    const handleLogin = () => setIsLoggedIn(true);
    const handleReset = () => setSelectedTheme(null);
    const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
    const [chatActive, setChatActive] = useState(() => {
        const saved = localStorage.getItem('chatActive');
        return saved === 'true';
    });
    const [showGuide, setShowGuide] = useState();
    const [autosave, setAutoSave] = useState(false);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            // Adding an explicit null check
            if (user !== null) {
                setIsLoggedIn(true);
                localStorage.setItem('isLoggedIn', 'true');
    
                // Continue only if user is valid and not null
                const docRef = doc(db, "users", user.uid);
                const unsubscribeFirestore = onSnapshot(docRef, (docSnapshot) => {
                    if (docSnapshot.exists()) {
                        setIsLoggedInReal(true);
                        const preferences = docSnapshot.data().preferences;
                        if (preferences) {
                            if ('showGuide' in preferences) {
                                setShowGuide(preferences.showGuide);
                            }
                            if ('autosave' in preferences) {
                                setAutoSave(preferences.autosave);
                            }
                        }
                    } else {
                        setIsLoggedInReal(false);
                    }
                });
                // Function call within the valid user condition
                return () => {
                    unsubscribeFirestore();
                };
            } else {
                setIsLoggedIn(false);
                setIsLoggedInReal(false);
                localStorage.setItem('isLoggedIn', 'false');
                localStorage.setItem('chatActive', 'false');
            }
        });
    
        return () => {
            unsubscribe(); // This will detach the auth state change listener
        };
    }, []);
    


    return (
        <ErrorBoundary>
            <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
                <BrowserRouter>
                    <CallsProvider>
                        <AppProvider
                            isLoggedIn={isLoggedIn}
                            chatActive={chatActive}
                            setChatActive={setChatActive}
                            showGuide={showGuide}
                            setShowGuide={setShowGuide}
                            autosave={autosave}
                            setAutoSave={setAutoSave}
                        >
                            <DndProvider backend={HTML5Backend}>
                                <div className="App">
                                    {isLoggedInReal ? (
                                        localStorage.getItem('chatActive') === 'true' && selectedTheme ? (
                                            <Chat selectedTheme={selectedTheme} />
                                        ) : (
                                            <Themes onSelect={setSelectedTheme} />
                                        )
                                    ) : (
                                        <LandingPage onLogin={setIsLoggedInReal} />
                                    )}
                                </div>
                            </DndProvider>
                        </AppProvider>
                    </CallsProvider>
                </BrowserRouter>
            </GoogleReCaptchaProvider>
        </ErrorBoundary>
    );
}
export default App;