// TailNode.js
import React from 'react';
import { Handle } from 'reactflow';
import tailSvg from './tail.svg'; // Ensure the path is correct

const TailNode = () => (
    <>
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" style={{ height: 0 }}>
      <defs>
        <filter id="shape-shadow" x="-50%" y="-50%" width="200%" height="200%">
            <feOffset result="offOut" in="SourceAlpha" dx="5" dy="5" />
            <feGaussianBlur result="blurOut" in="offOut" stdDeviation="5" />
            <feComponentTransfer>
                <feFuncA type="linear" slope="0.8"/>
            </feComponentTransfer>
            <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
        </filter>
      </defs>
    </svg>
    <div className="custom-node" style={{ width: '100px', height: '100px', filter: 'url(#shape-shadow)' }}>
      <img src={tailSvg} alt="Tail" style={{ width: '100%', height: '100%' }} />
    <Handle
      type="source" // Change to 'source' since this handle is the start of the edge
      position="right" // Positioned on the right to connect to circle 1
      id="tail-handle"
      style={{ top: '50%', transform: 'translateY(-50%)' }} // No need for negative right position
    />
  </div>
  </>
);

export default TailNode;
