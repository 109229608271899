import React, { useState, useEffect } from 'react';
import useStore from '../../store';
import straightLineIcon from './icons/straight_line.png';
import bezierIcon from './icons/bezier.png';
import stepLineIcon from './icons/step_line.png';
import smoothstepIcon from './icons/smoothstep.png';
import solid from './icons/solid.png';
import dashed from './icons/dashed.png';
import dotted from './icons/dotted.png';

const FsideMenu = ({ onCollapse }) => {
    const [selectedEdgeThickness, setSelectedEdgeThickness] = useState(2);
    const [isActive, setIsActive] = useState(true);
    const [isfCollapsed, setIsfCollapsed] = useState(false);
    const {
        updateNodeColor,
        updateEdgeColor,
        setSelectedNode,
        setSelectedEdge,
        removeNode,
        removeEdge,
    } = useStore(state => ({
        selectedNode: state.selectedNode,
        selectedEdge: state.selectedEdge,
        updateNodeColor: state.updateNodeColor,
        updateEdgeColor: state.updateEdgeColor,
        setSelectedNode: state.setSelectedNode,
        setSelectedEdge: state.setSelectedEdge,
        removeNode: state.removeNode,
        removeEdge: state.removeEdge,
    }));
    const updateEdgeStyle = useStore((state) => state.updateEdgeStyle);


    const colorPalette = ['#FF5733', '#33FF57', '#3357FF', '#FFFF33', '#FF33FF'];
    const textColorPalette = ['#000000', '#FFFFFF', '#FF0000', '#008000', '#0000FF'];
    const { nodes, selectedNodeId, updateNodeTextColor } = useStore();

    const selectedNode = useStore((state) => state.selectedNode);
    const selectedEdge = useStore((state) => state.selectedEdge);
    const handleColorChange = (color) => {
        if (selectedNode) {
            updateNodeColor(selectedNode, color);
        } else if (selectedEdge) {
            updateEdgeColor(selectedEdge, color);
        }
    };

    const handleTextColorChange = (color) => {
        if (selectedNode) {
            updateNodeTextColor(selectedNode, color);
        }
    };

    const toggleMenu = () => {
        setIsfCollapsed(!isfCollapsed);
    };

    useEffect(() => {
        if (selectedNode || selectedEdge) {
            setIsActive(true);
            setIsfCollapsed(false); // Add this line
        } else {
            setIsActive(false);
            setIsfCollapsed(true);
        }
    }, [selectedNode, selectedEdge]);

    const handleChangeColor = (newColor) => {
        if (selectedNode) {
            updateNodeColor(selectedNode, newColor); // Assuming updateNodeColor accepts nodeId and newColor
        } else if (selectedEdge) {
            updateEdgeColor(selectedEdge, newColor); // Assuming updateEdgeColor accepts edgeId and newColor
        }
    };
    const handleChangeType = (newType) => {
    };

    const hasNodesSelected = !!selectedNode;
    const hasEdgesSelected = !!selectedEdge;


    const handleDelete = () => {
        if (selectedNode) {
            removeNode(selectedNode); // Assuming removeNode accepts nodeId
            setSelectedNode(null);
        } else if (selectedEdge) {
            removeEdge(selectedEdge); // Assuming removeEdge accepts edgeId
            setSelectedEdge(null);
        }
    };

    const [selectedEdgeType, setSelectedEdgeType] = useState('default'); // Local state for selected edge type
    const setEdgeType = useStore((state) => state.setEdgeType); // Assume you have a method in your store for this

    const edgeTypes = [
        { value: 'straight', label: 'Straight', icons: straightLineIcon },
        { value: 'default', label: 'Bezier', icons: bezierIcon },
        { value: 'step', label: 'Step', icons: stepLineIcon },
        { value: 'smoothstep', label: 'Smoothstep', icons: smoothstepIcon },
    ];

    const isAnimatable = () => {
        // Retrieve the currently selected edge from your state.
        const edge = useStore.getState().edges.find(e => e.id === selectedEdge);
        const animatable = edge?.style?.strokeDasharray && edge.style.strokeDasharray !== '';
        return animatable;
    };


    useEffect(() => {
        if (selectedEdge) {
            const edge = useStore.getState().edges.find(e => e.id === selectedEdge);
        }
    }, [selectedEdge]);


    const isSelectedEdgeAnimatable = (newType) => {
        return newType !== 'straight';
    };

    const handleAnimationChange = () => {
        if (!selectedEdge) return;

        const isAnimated = !useStore.getState().edges.find(edge => edge.id === selectedEdge)?.animated;
        updateEdgeStyle(selectedEdge, selectedEdgeType, selectedStrokeStyle, isAnimated);
    };

    const [selectedEdgeAnimated, setSelectedEdgeAnimated] = useState(false); // Tracks if the selected edge is animated
    const [selectedStrokeStyle, setSelectedStrokeStyle] = useState(''); // Tracks the selected edge's stroke style

    const handleEdgeTypeChange = (newType) => {
        const edgeId = selectedEdge; // Retrieve this from your component's state or Zustand store
        const newStyle = {}; // Define new styles if needed, based on the edge type
        const animated = isSelectedEdgeAnimatable(newType); // Determine based on newType if it should be animated

        updateEdgeStyle(edgeId, newType, newStyle, animated);
    };

    const handleThicknessChange = (e) => {
        const newThickness = e.target.value;
        setSelectedEdgeThickness(newThickness); // Update state to reflect the new value
        updateEdgeStyle(selectedEdge, selectedEdgeType, { strokeWidth: newThickness }, selectedEdgeAnimated);
    };

    const strokeStyles = [
        { value: 'solid', label: 'Solid', cssValue: '', icons: solid},
        { value: 'dashed', label: 'Dashed', cssValue: '5,5', icons: dashed },
        { value: 'dotted', label: 'Dotted', cssValue: '1,5', icons: dotted },
    ];
    const handleStrokeStyleChange = (cssValue) => {
        if (!selectedEdge) return; // Ensure an edge is selected

        const newStyle = { strokeDasharray: cssValue };
        updateEdgeStyle(selectedEdge, selectedEdgeType, newStyle, selectedEdgeAnimated); // Use current edge properties
    };

    return (
        <div className={`fixed top-0 left-0 h-full flex flex-col ${isfCollapsed ? 'w-5vw' : 'w-15vw'} bg-gray-200 text-gray-800 shadow-lg transition-all duration-300`} style={{ zIndex: 2000 }}>
            <div className={`flex justify-end ${isfCollapsed ? 'w-5' : 'w-15'}`}>
                <button
                    onClick={toggleMenu}
                    className={`mt-5 mr-[-1.25rem] w-16 h-16 bg-gray-300 rounded flex items-center justify-center ${isfCollapsed ? '' : 'rotate-180'} transition-all duration-300`}
                >
                    <span className={`relative text-gray-800 text-4xl ${isfCollapsed ? '' : 'text-2xl'}`}>&raquo;</span>
                </button>
            </div>
            <div className={`p-6 overflow-auto ${isfCollapsed ? 'hidden' : 'block'}`}>
                <h2 className="text-lg font-semibold mb-4">Control Menu</h2>
                {hasNodesSelected && (
                    <div className="flex flex-col space-y-2">
                        <button onClick={handleDelete} className="bg-red-600 hover:bg-red-700 text-white font-semibold py-2 px-4 rounded">Delete Node</button>
                    </div>
                )}

                {hasEdgesSelected && (
                    <div className="flex flex-col space-y-2">
                        <button onClick={handleDelete} className="bg-red-600 hover:bg-red-700 text-white font-semibold py-2 px-4 rounded">Delete Edge</button>
                    </div>
                )}

                {!hasNodesSelected && !hasEdgesSelected && (
                    <p className="text-gray-300">No element selected.</p>
                )}

                {hasNodesSelected && (
                    <div className="mt-4">
                        <h3 className="text-md font-semibold mb-2">Select Color</h3>
                        <div className="flex space-x-2">
                            {colorPalette.map((color) => (
                                <button key={color} style={{ backgroundColor: color, width: '24px', height: '24px' }} onClick={() => handleColorChange(color)}></button>
                            ))}
                        </div>
                    </div>
                )}
                {hasEdgesSelected && (
                    <div className="mt-4">
                        <h3 className="text-md font-semibold mb-2">Select Color</h3>
                        <div className="flex space-x-2">
                            {colorPalette.map((color) => (
                                <button key={color} style={{ backgroundColor: color, width: '24px', height: '24px' }} onClick={() => handleColorChange(color)}></button>
                            ))}
                        </div>
                    </div>
                )}
                {hasNodesSelected && (
                    <div className="mt-4">
                        <h3 className="text-md font-semibold">Text Color</h3>
                        <div className="flex space-x-2">
                            {textColorPalette.map((color) => (
                                <button key={color} style={{ backgroundColor: color, width: '24px', height: '24px' }} onClick={() => handleTextColorChange(color)}></button>
                            ))}
                        </div>
                    </div>
                )}

            </div>
            {hasEdgesSelected && (
                <div className={`p-6 overflow-auto ${isfCollapsed ? 'hidden' : 'block'}`}>
                    <h3 className="text-md font-semibold">Line Type</h3>
                    <div className="edge-type-selector" style={{ display: 'flex', flexDirection: 'row', marginTop: '20px' }}>
                        {edgeTypes.map((type) => (
                            <button
                                key={type.value}
                                onClick={() => handleEdgeTypeChange(type.value)}
                                className={`edge-type-option ${selectedEdgeType === type.value ? 'selected' : ''}`}
                                style={{
                                    border: '1px solid lightgray',
                                    backgroundColor: 'white',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '30px',
                                    height: '30px',
                                    margin: '5px',
                                }}
                            >
                                <img src={type.icons} alt={type.label} title={type.label} />
                            </button>
                        ))}
                    </div>
                </div>
            )}
            {hasEdgesSelected && (
                <div className={`p-6 overflow-auto ${isfCollapsed ? 'hidden' : 'block'}`}>
                    <h3 className="text-md font-semibold">Style</h3>
                    <div className="stroke-style-selector" style={{ display: 'flex', flexDirection: 'row', marginTop: '20px' }}>
                        {strokeStyles.map((style) => (
                            <button
                                key={style.value}
                                onClick={() => handleStrokeStyleChange(style.cssValue)}
                                className={`stroke-style-option ${selectedStrokeStyle === style.value ? 'selected' : ''}`}
                                style={{
                                    border: '1px solid lightgray',
                                    backgroundColor: 'white',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '30px',
                                    height: '30px',
                                    margin: '5px',
                                }}
                            >
                                <img src={style.icons} alt={style.label} />
                            </button>
                        ))}
                    </div>
                </div>
            )}
            {hasEdgesSelected && (
                <div className={`p-6 overflow-auto ${isfCollapsed ? 'hidden' : 'block'}`}>
                    <h3 className="text-md font-semibold">Animate</h3>
                    <button
                        disabled={!isAnimatable(selectedEdge.style)}
                        onClick={handleAnimationChange}
                        style={{
                            marginLeft: '10px',
                            width: '100px',
                            height: '30px',
                            backgroundColor: isAnimatable(selectedEdge.style) ? 'green' : 'lightgray',
                            color: 'white',
                            border: 'none',
                            borderRadius: '4px',
                            cursor: isAnimatable(selectedEdge.style) ? 'pointer' : 'not-allowed',
                        }}
                    >
                        Animate
                    </button>
                </div>
            )}

            {hasEdgesSelected && (
                <div className={`p-6 overflow-auto ${isfCollapsed ? 'hidden' : 'block'}`}>
                    <h3 className="text-md font-semibold">Stroke Thickness</h3>
                    <select onChange={handleThicknessChange}>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>

                    </select>
                </div>
            )}

        </div>
    );
};

export default FsideMenu;
