// Adjust imports to match your project's structure
import { db, auth } from '../../firebase-config'; // Adjust the path as per your project structure
import { doc, getDoc, updateDoc } from "firebase/firestore"; 

// Function to fetch user token allowances from Firestore
export const fetchUserTokenAllowance = async (userId) => {
  // Check if a user is logged in
  if (!auth.currentUser || auth.currentUser.uid !== userId) {
    console.log('No user is logged in or the logged-in user does not match the requested user');
    return null;
  }

  const userDocRef = doc(db, 'users', userId);
  const userDoc = await getDoc(userDocRef);
  if (!userDoc.exists()) {
    console.error('User not found');
    return null;
  }
  const tokens = userDoc.data().tokens; // Assuming tokens are stored under this structure
  return tokens;
};

// Function to calculate token usage from a chatSet
export const calculateTokenUsageFromChatSet = (chatSet) => {
  // Assuming 'chatSet' contains 'geminiTokenUsed' and 'gptTokenUsed' fields
  const tokenUsage = chatSet.totalTokenUsed;
  console.log('Token usage from chat set:', tokenUsage);
  return tokenUsage;
};
