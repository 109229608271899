export const nodes = [
    {
        id: 'tail',
        type: 'tailNode',
        position: { x: 50, y: 225 },
        data: {},
        draggable: false,
    },
    // Circle nodes as custom type without labels
    {
        id: 'circle-1',
        type: 'circleNode',
        position: { x: 200, y: 250 },
        draggable: false,
        selectable: false,
    },
    {
        id: 'circle-2',
        type: 'circleNode',
        position: { x: 285, y: 250 },
        draggable: false,
        selectable: false,
    },
    {
        id: 'circle-3',
        type: 'circleNode',
        position: { x: 370, y: 250 },
        draggable: false,
        selectable: false,
    },
    {
        id: 'circle-4',
        type: 'circleNode',
        position: { x: 455, y: 250 },
        draggable: false,
        selectable: false,
    },
    {
        id: 'circle-5',
        type: 'circleNode',
        position: { x: 540, y: 250 },
        draggable: false,
        selectable: false,
    },
    {
        id: 'head',
        type: 'headNode',
        position: { x: 650, y: 225 },
        data: {},
        draggable: false,
        selectable: false,
    },

    {
        id: 'equipment',
        type: 'equipment',
        position: { x: 170, y: 150 },
        data: { label: 'Equipment' },
        draggable: false,
        selectable: false,
    },
    {
        id: 'environment',
        type: 'environment',
        position: { x: 255, y: 150 },
        data: { label: 'Environment' },
        draggable: false,
        selectable: false,
    },
    {
        id: 'patientFactors',
        type: 'patientFactors',
        position: { x: 340, y: 150 },
        data: { label: 'Patient Factors' },
        draggable: false,
        selectable: false,
    },
    {
        id: 'staffFactors',
        type: 'staffFactors',
        position: { x: 425, y: 150 },
        data: { label: 'Staff Factors' },
        draggable: false,
        selectable: false,
    },
    {
        id: 'taskFactors',
        type: 'taskFactors',
        position: { x: 510, y: 150 },
        data: { label: 'Task Factors' },
        draggable: false,
        selectable: false,
    },
    {
        id: 'communication',
        type: 'communication',
        position: { x: 170, y: 350 },
        data: { label: 'Communication' },
        draggable: false,
        selectable: false,
    },
    {
        id: 'educationTraining',
        type: 'educationTraining',
        position: { x: 255, y: 350 },
        data: { label: 'Education & Training' },
        draggable: false,
        selectable: false,
    },
    {
        id: 'teamFactors',
        type: 'teamFactors',
        position: { x: 340, y: 350 },
        data: { label: 'Team Factors' },
        draggable: false,
        selectable: false,
    },
    {
        id: 'organizationalFactors',
        type: 'organizationalFactors',
        position: { x: 425, y: 350 },
        data: { label: 'Organizational Factors' },
        draggable: false,
        selectable: false,
    },
    {
        id: 'clinicalManagement',
        type: 'clinicalManagement',
        position: { x: 510, y: 350 },
        data: { label: 'Clinical Management' },
        draggable: false,
        selectable: false,
        isInteractive: false,
    },
    
];

export const edges = [

    {
        id: 'e-equipment-circle1-top',
        source: 'equipment',
        target: 'circle-1',
        targetHandle: 'input1',
        type: 'smoothstep',
        animated: true,
        selectable: false,
        isInteractive: false,
    },
    {
        id: 'e-environment-circle2-top',
        source: 'environment',
        target: 'circle-2',
        targetHandle: 'input1',
        type: 'smoothstep',
        animated: true,
        selectable: false,
        isInteractive: false,
    },
    {
        id: 'e-patientFactors-circle3-top',
        source: 'patientFactors',
        target: 'circle-3',
        targetHandle: 'input1',
        type: 'smoothstep',
        animated: true,
        selectable: false,
        isInteractive: false,
    },
    {
        id: 'e-staffFactors-circle4-top',
        source: 'staffFactors',
        target: 'circle-4',
        targetHandle: 'input1',
        type: 'smoothstep',
        animated: true,
        selectable: false,
        isInteractive: false,
    },
    {
        id: 'e-taskFactors-circle5-top',
        source: 'taskFactors',
        target: 'circle-5',
        targetHandle: 'input1',
        type: 'smoothstep',
        animated: true,
        selectable: false,
        isInteractive: false,
    },
    {
        id: 'e-communication-circle1-bottom',
        source: 'communication',
        target: 'circle-1',
        targetHandle: 'input2',
        type: 'smoothstep',
        animated: true,
        selectable: false,
        isInteractive: false,
    },
    {
        id: 'e-educationTraining-circle2-bottom',
        source: 'educationTraining',
        target: 'circle-2',
        targetHandle: 'input2',
        type: 'smoothstep',
        animated: true,
        selectable: false,
        isInteractive: false,
    },
    {
        id: 'e-teamFactors-circle3-bottom',
        source: 'teamFactors',
        target: 'circle-3',
        targetHandle: 'input2',
        type: 'smoothstep',
        animated: true,
        selectable: false,
        isInteractive: false,
    },
    {
        id: 'e-organizationalFactors-circle4-bottom',
        source: 'organizationalFactors',
        target: 'circle-4',
        targetHandle: 'input2',
        type: 'smoothstep',
        animated: true,
        selectable: false,
        isInteractive: false,
    },
    {
        id: 'e-clinicalManagement-circle5-bottom',
        source: 'clinicalManagement',
        target: 'circle-5',
        targetHandle: 'input2',
        type: 'smoothstep',
        animated: true,
        selectable: false,
        isInteractive: false,
    },
    

    {
        id: 'e-tail-circle-1',
        source: 'tail',             // id of the tail node
        target: 'circle-1',         // id of the first circle node
        sourceHandle: 'tail-handle', // The handle identifier must match the tail's source handle id
        animated: true,
        type: 'straight',
        selectable: false,
        isInteractive: false,
      },
      // Connect each circle to the next
      ...Array.from({ length: 4 }).map((_, index) => ({
        id: `e-circle-${index + 1}-circle-${index + 2}`,
        source: `circle-${index + 1}`,
        target: `circle-${index + 2}`,
        animated: true,
        type: 'straight',
        selectable: false,
        isInteractive: false,
      })),
      // Connect the last circle to the head
      {
        id: 'e-circle-5-head',
        source: 'circle-5',        // id of the last circle node
        target: 'head',            // id of the head node
        targetHandle: 'head-handle', // The handle identifier must match the head's target handle id
        animated: true,
        type: 'straight',
        selectable: false,
        isInteractive: false,
      },

];
