import React, { useCallback, useRef, useState, useEffect } from 'react';
import ReactFlow, {
  addEdge,
  MiniMap,
  Controls,
  Background,
} from 'reactflow';
import { useDrop } from 'react-dnd';
import CustomNode from './Components/fishbone/CustomNode';
import 'reactflow/dist/style.css';
import './Components/fishbone/fishCss.css';
import HeadNode from './Components/fishbone/HeadNode';
import TailNode from './Components/fishbone/TailNode';
import CircleNode from './Components/fishbone/CicrleNode';
import { Equipment, Environment, PatientFactors, StaffFactors, TaskFactors, Communication, EducationTraining, TeamFactors, OrganizationalFactors, ClinicalManagement } from './Components/fishbone/FishItem';
import useStore from './store';
import FsideMenu from './Components/fishbone/FsideMenu';
import './Components/fishbone/FsideMenu.css';
import logo from './logo.png';

const nodeTypes = {
  headNode: HeadNode,
  tailNode: TailNode,
  circleNode: CircleNode,
  equipment: Equipment,
  environment: Environment,
  patientFactors: PatientFactors,
  staffFactors: StaffFactors,
  taskFactors: TaskFactors,
  communication: Communication,
  educationTraining: EducationTraining,
  teamFactors: TeamFactors,
  organizationalFactors: OrganizationalFactors,
  clinicalManagement: ClinicalManagement,
  custom: CustomNode,  
};

const OverviewFlow = (props) => {
  
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);

  const { nodes, edges, setEdges } = useStore(state => ({
    nodes: state.nodes,
    edges: state.edges,
    setNodes: state.setNodes,
    setEdges: state.setEdges,
    onNodesChange: state.onNodesChange,
    onEdgesChange: state.onEdgesChange,
  }));

  const onEdgesChange = useCallback((changes) => {
    useStore.getState().onEdgesChange(changes.map(change => {
        // Find the existing edge to preserve its type
        const existingEdge = useStore.getState().edges.find(edge => edge.id === change.id);
        if (existingEdge && change.type === 'select') {
            // Preserve the original type of the edge, if it exists
            return { ...change, type: existingEdge.type };
        }
        return change;
    }));
}, []);



  const onNodesChange = useCallback((changes) => {
    changes.forEach((change) => {
      if (change.type === 'position' && change.position) {
        useStore.getState().updateNodePosition(change.id, change.position);
      }
    });
  }, []);

  const reactFlowWrapper = useRef(null);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const proOptions = { hideAttribution: true };

  const { 
    setNodes, 
    removeNode, 
    removeEdge, 
    setSelectedNode, 
    setSelectedEdge, 
    selectedNode, // Add this line
    selectedEdge  // Add this line
  } = useStore(state => ({
    setNodes: state.setNodes,
    removeNode: state.removeNode,
    removeEdge: state.removeEdge,
    setSelectedNode: state.setSelectedNode,
    setSelectedEdge: state.setSelectedEdge,
    selectedNode: state.selectedNode, // Add this line
    selectedEdge: state.selectedEdge  // Add this line
  }));

  // Handling Delete key for node and edge deletion
  useEffect(() => {
    const handleKeyDown = (event) => {
      // Trigger the modal for deletion confirmation only if there's a selected node or edge.
      if (event.key === 'Delete' && (selectedNode || selectedEdge)) {
        setShowModal(true);
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [selectedNode, selectedEdge, setShowModal]);

  const handleConfirmDelete = () => {
    // Perform the delete operation based on what's currently selected.
    if (selectedNode) {
      removeNode(selectedNode);
      setSelectedNode(null); // Optionally clear the selection
    } else if (selectedEdge) {
      removeEdge(selectedEdge);
      setSelectedEdge(null); // Optionally clear the selection
    }

    setShowModal(false); // Close the modal after deletion
  };


// Drop handling for adding new custom nodes
const [, drop] = useDrop(() => ({
  accept: 'MENU_ITEM',
  drop: (item, monitor) => {
    if (!reactFlowInstance) return;

    const clientOffset = monitor.getClientOffset();
    if (!clientOffset) return;

    // Make sure the method used here matches what's available on your reactFlowInstance
    const position = reactFlowInstance.screenToFlowPosition(clientOffset);
    if (!position) {
      console.error('Failed to calculate node position.');
      return;
    }

    const newNode = {
      id: `${item.id}-${Date.now()}`,
      type: 'custom', // Hardcoded type as 'custom'
      position,
      data: { label: `${item.type}` }, // Set label to item.type
    };

    // Assuming setNodes is a method within your store for adding a new node
    useStore.getState().setNodes(ns => [...ns, newNode]);
  },
}), [reactFlowInstance]);

const onNodeClick = useCallback((event, node) => {
  useStore.getState().setSelectedNode(node.id);

}, []);

const onEdgeClick = useCallback((event, edge) => {
  setSelectedEdge(edge.id);

}, []);


  const onConnect = useCallback((params) => {
    // Use React Flow's addEdge function to create the new edges array
    const newEdges = addEdge({ ...params, type: 'smoothstep', animated: true }, edges);

    // Update the Zustand store with this new array
    setEdges(newEdges);
  }, [edges, setEdges]);

  useEffect(() => {
    const updateSize = () => {
      if (reactFlowWrapper.current) {
        setReactFlowInstance(reactFlowWrapper.current.getBoundingClientRect());
      }
    };

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const onNodeDragStop = useCallback((event, node) => {
    // Directly call your store's function to update node position
    useStore.getState().updateNodePosition(node.id, node.position);
  }, []);

  const onPaneClick = () => {
    // Deselect any selected node or edge
    setSelectedNode(null);
    setSelectedEdge(null);
  };

  return (
    <div style={{ width: '100vw', height: '100vh' }}>
      <div style={{ position: 'absolute', top: 0, left: 0, zIndex: 1000 }}>
      </div>

      <div
        id="reactflow-wrapper"
        ref={drop}
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '50vw',
            height: '50vh',
            backgroundImage: `url(${logo})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
            opacity: 0.05,
            zIndex: -1,
            nodeTypes:{nodeTypes},
          }}
        />
        <FsideMenu />
        <ReactFlow
          onConnect={onConnect}
          onNodeDragStop={onNodeDragStop}
          nodes={nodes}
          edges={edges}
          onInit={setReactFlowInstance}
          fitView
          nodeTypes={nodeTypes}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onPaneClick={onPaneClick}
          onNodeClick={onNodeClick}
          onEdgeClick={onEdgeClick}
          minZoom={0.2}
          maxZoom={6}
          proOptions={proOptions}
        >
          <MiniMap />
          <Controls />
          <Background variant="dots" gap={12} size={0.5} color="#00005" />


        </ReactFlow>
      </div>
      {showModal && (
        <div className="fixed z-50 inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-8 rounded-lg shadow">
            <p className="text-center mb-4">Are you sure you want to delete the selected elements?</p>
            <div className="flex justify-center mt-2">
              <button onClick={() => setShowModal2(false)} className="mr-6 bg-gray-200 px-4 py-2 rounded p-2">No</button>
              <button onClick={handleConfirmDelete} className="ml-6 bg-red-500 text-white px-4 py-2 rounded p-2">Yes</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OverviewFlow;
