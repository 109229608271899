import React, { useState, useEffect } from 'react';
import { Handle, Position, NodeResizer } from 'reactflow';
import useStore from '../../store';

const CustomNode = ({ id, data, selected }) => {
  const { nodes, updateNodeSize, updateNodeLabel } = useStore();

  const currentNode = nodes.find(node => node.id === id);
console.log('Current node:', currentNode); // Add this line
  const [isEditing, setIsEditing] = useState(false);
  const [editText, setEditText] = useState(data.label);

  const [dimensions, setDimensions] = useState({
    width: currentNode?.data.width || 120,
    height: currentNode?.data.height || 30,
  });

  console.log('Width:', dimensions.width); // Add this line

useEffect(() => {
  if (currentNode?.data) {
    const { width, height } = currentNode.data;
    setDimensions({ width, height });
    console.log('Updated dimensions:', width, height); // Add this line
  }
}, [currentNode]);

  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  const handleBlur = () => {
    setIsEditing(false);
    updateNodeLabel(id, editText);
  };

  const handleChange = (event) => {
    setEditText(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleBlur(); // Assuming handleBlur commits the change and exits edit mode
      event.preventDefault(); // Prevent the default action to avoid any form submission, if applicable
    }
  };

  const handleResize = (event, data) => {
    if (selected) { // Apply resizing only if the node is selected
      setDimensions({
        width: data.width,
        height: data.height,
      });
      updateNodeSize(id, data.width, data.height);
    }
  };
  
  const handlePointerDown = (event) => {
    if (selected) {
      event.stopPropagation();
    }
  };

  return (
    <div
      className="custom-node2"
      style={{
        backgroundColor: data.color || '#fff',
        borderRadius: '5%',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        overflow: 'visible',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.15)',
        border: '1px solid lightgray',
        fontSize: '12px',
        width: `${currentNode?.data.width}px`,
        height: `${currentNode?.data.height}px`,
      }}
      onDoubleClick={handleDoubleClick}
      onMouseDown={handlePointerDown}
    >
      {selected && (
        <NodeResizer
          nodeId={id}
          isVisible={true}
          minWidth={100}
          minHeight={30}
          onResize={handleResize}
          style={{ border: '4px solid #000' }}
        />
      )}
      <div
        style={{
          width: `${currentNode?.data.width}px`,
          height: `${currentNode?.data.height}px`,
        }}
      >
        {isEditing ? (
          <textarea
            value={editText}
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyDown={(event) => event.key === 'Enter' && handleBlur()}
            autoFocus
            style={{ fontSize: '14px', width: '90%', height: '50px' }} // Adjust height as needed
          />
        ) : (
          <div style={{ color: data.textColor, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>{data.label}</div>
        )}
        <Handle type="target" position={Position.Top} id={`${id}-t`} className="handle-top" />
        <Handle type="target" position={Position.Right} id={`${id}-r`} className="handle-right" />
        <Handle type="source" position={Position.Bottom} id={`${id}-b`} className="handle-bottom" />
        <Handle type="source" position={Position.Left} id={`${id}-l`} className="handle-left" />
      </div>
    </div>
  );
  
    };
    
    export default CustomNode;