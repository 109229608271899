import React, { createContext, useState, useEffect } from 'react';
import { signOut, getIdToken, onAuthStateChanged } from 'firebase/auth';
import { auth, db } from '../../firebase-config';
import {
    doc,
    getDoc,
    setDoc,
    updateDoc,
    deleteDoc,
    serverTimestamp,
    addDoc,
    collection,
    arrayUnion,
    increment
} from 'firebase/firestore';
import caseFiles from '../../Components/cases/cases_with_scenarios.json';
import { jsonrepair } from 'jsonrepair';
import { fetchUserTokenAllowance, calculateTokenUsageFromChatSet } from './userUse';

const { v4: uuidv4 } = require('uuid');
export const AppContext = createContext();

const initialSessionId = localStorage.getItem('currentSessionId');

export const AppProvider = ({
    children,
    isLoggedIn,
    chatActive,
    setChatActive,
    showGuide,
    setShowGuide,
    autosave,
    setAutoSave,
}) => {
    const [chatMessages, setChatMessages] = useState([]);
    const [agents, setAgents] = useState(() => {
        const savedAgents = localStorage.getItem('agents');
        return savedAgents ? JSON.parse(savedAgents) : [];
    });
    const [message, setMessage] = useState('');
    const [incidentDescription, setIncidentDescription] = useState('');
    const [accumulatedResponses, setAccumulatedResponses] = useState('');
    const [calls, setCalls] = useState(0);
    const [selectedTheme, setSelectedTheme] = useState(null);
    const [dataToSendToMentor, setDataToSendToMentor] = useState(null);
    const [userAndAgentsInteractions, setUserAndAgentsInteractions] = useState([]);
    const [totalScore, setTotalScore] = useState(0);
    const [parsedFeedback, setParsedFeedback] = useState({});
    const [feedbackCount, setFeedbackCount] = useState(0);
    const [feedbackDetails, setFeedbackDetails] = useState({});
    const [mentorFeedbackDetails, setMentorFeedbackDetails] = useState({});
    const [mentorTotalScore, setMentorTotalScore] = useState(0);
    const [isGuideVisible, setIsGuideVisible] = useState(false);
    const [isMentorCalculating, setIsMentorCalculating] = useState(false);
    const [saveStatus, setSaveStatus] = useState('');
    const [currentSessionId, setCurrentSessionId] = useState(initialSessionId);
    const [sessions, setSessions] = useState([]);
    const [firebaseToken, setFirebaseToken] = useState(null);
    const [selectedAgent, setSelectedAgent] = useState(null);
    const [caseDetails, setCaseDetails] = useState({});
    const [selectedScenario, setSelectedScenario] = useState({});
    const [currentSession, setCurrentSession] = useState(null);
    const [mentorFeedback, setMentorFeedback] = useState([]);
    const [mentorCalculating, setMentorCalculating] = useState(false);
    const [chatSets, setChatSets] = useState([]);
    const [mentorSets, setMentorSets] = useState([]);
    const [mentorScoringSets, setMentorScoringSets] = useState([]);
    const [gptResponse, setGptResponse] = useState('');
    const [tokenAllowances, setTokenAllowances] = useState({
        dailyAllowance: 0,
        usedDailyAllowance: 0,
        totalAllowance: 0,
        usedTotalAllowance: 0,
    });
    const [isWaitingAgentForResponse, setIsWaitingAgentForResponse] = useState(false);
    const [isCreateSessionVisible, setIsCreateSessionVisible] = useState(false);
    const [isMessageBeingProcessed, setIsMessageBeingProcessed] = useState(false);
    const [isServerBusyModalOpen, setIsServerBusyModalOpen] = useState(false);


    useEffect(() => {
        localStorage.setItem('chatActive', chatActive.toString());
    }, [chatActive]);


    useEffect(() => {
        const checkAndUpdateTokenAllowances = async () => {
            // Ensure there's a logged-in user
            const user = auth.currentUser;
            if (user) {
                // Fetch token allowances from Firestore
                const tokens = await fetchUserTokenAllowance(user.uid);
            }
        };

        // Register auth state observer
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                checkAndUpdateTokenAllowances();
            }
        });

        // Cleanup subscription
        return () => unsubscribe();
    }, []); // Runs only once on mount

    useEffect(() => {
        // Calculate token usage for the latest chat set and update Firestore
        const updateTokenUsageForLatestChatSet = async () => {
            if (chatSets.length > 0) {
                const latestChatSet = chatSets[chatSets.length - 1];
                const tokenUsage = calculateTokenUsageFromChatSet(latestChatSet);
            }
        };
        updateTokenUsageForLatestChatSet();
    }, [chatSets]); // Runs whenever chatSets changes

    const [isUserCheckComplete, setIsUserCheckComplete] = useState(false);
    useEffect(() => {
        if (auth.currentUser) {
            setIsUserCheckComplete(true);
        } else {
            auth.onAuthStateChanged(user => {
                if (user) {
                    setIsUserCheckComplete(true);
                }
            });
        }
    }, []);

    // Initialization and cleanup logic
    useEffect(() => {
        const fetchSessionDetails = async () => {
            if (!isUserCheckComplete) {
                return;
            }
            if (!currentSessionId) return; // Exit if there's no session ID set

            try {
                const sessionDocRef = doc(db, "sessions", currentSessionId);
                const docSnap = await getDoc(sessionDocRef);

                if (docSnap.exists()) {
                    const sessionData = docSnap.data();

                    // Set state based on fetched session data
                    setCaseDetails(sessionData.caseDetails || []);
                    setSelectedScenario(sessionData.selectedScenario || []);
                    setChatMessages(sessionData.chatSets || []);
                    setTotalScore(sessionData.totalScore || 0);
                    setMentorTotalScore(sessionData.mentorTotalScore || 0);
                    setChatSets(sessionData.chatSets || []);
                    setMentorSets(sessionData.mentorSets || []);
                    setMentorScoringSets(sessionData.mentorScoringSets || []);
                    setAgents(sessionData.agents || []);

                    setAccumulatedResponses(sessionData.newAccumulatedResponses || "");

                    // Additional state updates as necessary, based on sessionData structure

                } else {
                    console.error("No such document!");
                    // Handle scenario where session data doesn't exist in Firestore
                }
            } catch (error) {
                console.error("Error fetching session details:", error);
                // Handle error, possibly setting an error state or showing a notification to the user
            }
        };
        loadChatMessagesFromSession(); // Call the function to load chat messages from Firestore

        fetchSessionDetails();
    }, [currentSessionId, isUserCheckComplete]); // Dependency array ensures this runs when currentSessionId changes

    
    // part 1 above done ==============================================================================================

    const syncSessionWithFirestore = async ({
        sessionName, theme, agents, selectedScenario, chatSets, mentorSets, mentorScoringSets, mentorFeedback, currentSessionId
    }) => {
        if (!currentSessionId) {
            console.error("No current session ID provided for syncing.");
            return;
        }

        const firestoreUpdate = {
            ...(sessionName && { name: sessionName }),
            ...(theme && { theme }),
            ...(agents && { agents }),
            ...(selectedScenario && { selectedScenario: { ...selectedScenario } }),
            ...(chatSets && { chatSets: [...chatSets] }),
            ...(mentorSets && { mentorSets: [...mentorSets] }),
            ...(mentorScoringSets && { mentorScoringSets: [...mentorScoringSets] }),
            ...(mentorFeedback && { mentorFeedback: [...mentorFeedback] }),
            // Include other fields as needed
            lastUpdated: serverTimestamp(),
        };

        try {
            const sessionDocRef = doc(db, "sessions", currentSessionId);
            await updateDoc(sessionDocRef, firestoreUpdate);
        } catch (error) {
            console.error("Error syncing session details with Firestore:", error);
        }
    };





    useEffect(() => {
    }, [isGuideVisible, showGuide]);


    const addSession = async (sessionName, theme) => {
        const user = auth.currentUser;
        if (!user) {
            return;
        }

        const caseDetails = caseFiles.find(caseItem => caseItem.Theme === theme);
        if (!caseDetails) {
            console.error("Theme not found:", theme);
            return;
        }
        const scenarioKeys = Object.keys(caseDetails.Scenarios);
        const randomScenarioKey = scenarioKeys[Math.floor(Math.random() * scenarioKeys.length)];
        const selectedScenario = caseDetails.Scenarios[randomScenarioKey];

        try {
            const sessionDocRef = await addDoc(collection(db, "sessions"), {
                userId: user.uid,
                name: sessionName,
                theme,
                caseDetails,
                selectedScenario,
                agents,
                createdAt: serverTimestamp(),
                chatSets,
                mentorSets,
                mentorScoringSets,
            });

            // After successful Firestore document creation, set the context state
            setCurrentSessionId(sessionDocRef.id);
            localStorage.setItem('currentSessionId', sessionDocRef.id);

            setChatActive(true); // Set chat as active immediately after session creation
            localStorage.setItem('chatActive', 'true');

            // Since `sessions` state is likely an array of session objects, append the new session
            setSessions(prevSessions => [
                ...prevSessions,
                {
                    id: sessionDocRef.id,
                    userId: user.uid,
                    name: sessionName,
                    theme,
                    caseDetails,
                    selectedScenario,
                    agents,
                    createdAt: new Date().toISOString(),
                    chatSets,
                    mentorSets,
                    mentorScoringSets,
                },
            ]);

        } catch (error) {
            console.error("Error during session creation or mentor feedback initialization:", error);
        }
    };




    const loadSession = async (sessionId) => {
        const user = auth.currentUser;
        if (!user) {
            console.error("User must be signed in to load sessions");
            return;
        }

        const sessionDocRef = doc(db, "sessions", sessionId);
        const sessionSnap = await getDoc(sessionDocRef);

        if (!sessionSnap.exists() || sessionSnap.data().userId !== user.uid) {
            console.error(`No session found with ID ${sessionId}, or session does not belong to the current user.`);
            return;
        }

        const sessionData = sessionSnap.data();

        // Extract all session details that are also set during session creation
        const { name, theme, caseDetails, selectedScenario, agents, createdAt, chatSets, mentorSets, mentorScoringSets } = sessionData;

        // Update context and local storage to mirror addSession functionality
        setCurrentSessionId(sessionId);
        localStorage.setItem('currentSessionId', sessionId);

        setChatActive(true);
        localStorage.setItem('chatActive', 'true');

        // Update the current session context without altering other sessions
        setSelectedTheme(theme.Theme);
        localStorage.setItem('selectedTheme', theme);
        setCurrentSession({
            id: sessionId,
            userId: user.uid,
            name,
            theme,
            caseDetails,
            selectedScenario,
            agents,
            createdAt: createdAt.toDate().toISOString(), // Convert timestamp to ISO string
            chatSets,
            mentorSets,
            mentorScoringSets
        });
        setChatSets(chatSets);
        localStorage.setItem('setAgents', JSON.stringify(theme.Agents));
        setMentorFeedback(mentorSets);
        loadChatMessagesFromSession();
    };

    // *********************************************************************************/

    const updateSessionName = async (sessionId, newSessionName) => {
        const userId = auth.currentUser?.uid;
        if (!userId) {
            console.error("User not authenticated");
            return;
        }

        const sessionDocRef = doc(db, "sessions", sessionId);
        const docSnap = await getDoc(sessionDocRef);

        if (docSnap.exists() && docSnap.data().userId === userId) {
            await updateDoc(sessionDocRef, { name: newSessionName });

            // Update local context state
            setSessions(prevSessions => prevSessions.map(session =>
                session.id === sessionId ? { ...session, name: newSessionName } : session
            ));

            // Optionally, update the current session if it's the one being renamed
            if (currentSessionId === sessionId) {
                setCurrentSession(prevSession => ({ ...prevSession, name: newSessionName }));
            }
        } else {
            console.error(`No session found with ID ${sessionId} or session does not belong to the current user`);
        }
    };

    // *********************************************************************************/
    // thiese need addessing  

    const removeSession = async (sessionId) => {
        const userId = auth.currentUser?.uid;
        if (!userId) {
            console.error("User not authenticated");
            return;
        }

        // Verify session belongs to the user before deletion
        const sessionDocRef = doc(db, "sessions", sessionId);
        const sessionSnap = await getDoc(sessionDocRef);

        if (!sessionSnap.exists() || sessionSnap.data().userId !== userId) {
            console.error("Session not found or does not belong to the current user.");
            return;
        }

        // Delete the session from the 'sessions' collection
        await deleteDoc(sessionDocRef);


        // Update local sessions state, assuming 'sessions' state is an array of session objects
        setSessions(prevSessions => prevSessions.filter(session => session.id !== sessionId));

        // If the session being deleted is the currently active session, reset chat active state
        if (currentSessionId === sessionId) {
            setChatActive(false);
            setCurrentSessionId(null);
            setCurrentSession(null);
        }
    };



    const handleManualSave = async () => {
        setSaveStatus('Saving...');

        const firestoreUpdate = {
        };
        const feedbackDetails = {
        };

        try {
            if (currentSessionId) {
                await updateDoc(doc(db, "sessions", currentSessionId), {
                    ...firestoreUpdate,
                    lastSaved: serverTimestamp() // Track the last manual save timestamp
                });
            }

            // If there's mentor feedback associated with the session, save it as well
            if (feedbackDetails && feedbackDetails.sessionId) {
                await setDoc(doc(db, "mentorFeedback", feedbackDetails.id), {
                    ...feedbackDetails,
                    lastSaved: serverTimestamp() // Track the last manual save timestamp for the feedback
                });
            }
            setSaveStatus('Saved');
            setTimeout(() => setSaveStatus(''), 2000); // Reset status after 2 seconds
        } catch (error) {
            console.error("Error saving session or feedback:", error);
            setSaveStatus('Error saving');
            setTimeout(() => setSaveStatus(''), 2000); // Reset status after 2 seconds
        }
    };

    // up to here
    // *********************************************************************************/
    // *********************************************************************************/


    useEffect(() => {
        let interval;

        if (autosave && currentSessionId) {
            interval = setInterval(async () => {
                try {
                    const sessionDocRef = doc(db, "sessions", currentSessionId);
                    await updateDoc(sessionDocRef, {
                        lastAutoSaved: serverTimestamp(),
                    });
                } catch (error) {
                    console.error("Error during auto-save:", error);
                }
            }, 300000);
        }

        return () => clearInterval(interval);
    }, [autosave, currentSessionId]);




    const getTheToken = async () => {
        if (auth.currentUser) {
            const newToken = await getIdToken(auth.currentUser, true);
            setFirebaseToken(newToken);
            return newToken; // Return the newly fetched token
        }
        return null; // Return null if no user is logged in
    };

    // Function to update session in Firestore with new chat entry
    async function updateSessionInFirestore(currentSessionId, newChatSet, newAccumulatedResponses) {
        const sessionRef = doc(db, 'sessions', currentSessionId);

        try {
            await updateDoc(sessionRef, {
                chatSets: arrayUnion(newChatSet),
                newAccumulatedResponses: newAccumulatedResponses // Updating the field with the latest summary or conversation
            });
        } catch (error) {
            console.error('Error updating session with new chat entry and conversation summary:', error);
        }
    }

    async function updateMentorSessionInFirestore(currentSessionId, newMentorSet) {
        const sessionRef = doc(db, 'sessions', currentSessionId);

        try {
            await updateDoc(sessionRef, {
                mentorSets: arrayUnion(newMentorSet),
            });
        } catch (error) {
            console.error('Error updating session with new mentor entry:', error);
        }
    }



    // Assume this function is called on page load or reload to fetch and set chat messages
    const loadChatMessagesFromSession = async () => {

        if (!auth.currentUser) {
            console.log('No user is logged in');
            return;
          }
        if (!currentSessionId) return; // Ensure there's a session ID to fetch data for

        const sessionDocRef = doc(db, "sessions", currentSessionId);
        const docSnap = await getDoc(sessionDocRef);
        if (docSnap.exists()) {
            const sessionData = docSnap.data();
            // Assuming chatSets is an array of objects similar to newChatSet structure
            const chatSets = sessionData.chatSets || [];
            // Reconstruct chatMessages from chatSets for UI
            const reconstructedChatMessages = chatSets.map(chatSet => [
                { agent: 'User', userMessage: chatSet.userMessage },
                { agent: chatSet.agent, message: chatSet.gptResponse }
            ]).flat();

            setChatMessages(reconstructedChatMessages);

            // Check if there is a mentorSet to process
            const mentorSets = sessionData.mentorSets || [];
            const lastMentorSet = mentorSets[mentorSets.length - 1];

            if (lastMentorSet) {
                const parsedFeedback = parseFeedback2(lastMentorSet.mentor_gpt_response);
                if (parsedFeedback) {
                    await updateFeedbackAndScore2(parsedFeedback);

                }

            }

        } else {
            console.error("No such document!");
        }
    };

    useEffect(() => {
    }, [mentorFeedbackDetails, mentorTotalScore]);


    // **********************************************************************//
    // below function is for updating token allowances in firestore from agent function

    const updateTokenAllowancesInFirestore = async (AgentTokenUsed) => {
        const userId = auth.currentUser?.uid;
        if (!userId) {
            console.error("User is not authenticated.");
            return;
        }

        const userTokensRef = doc(db, 'users', userId);
        try {
            await updateDoc(userTokensRef, {
                'tokens.usedDailyAllowance': increment(AgentTokenUsed),
                'tokens.usedTotalAllowance': increment(AgentTokenUsed)
            });
        } catch (error) {
            console.error('Error updating token allowances:', error);
        }
    };

    const updateMenotrTokenAllowancesInFirestore = async (mentorTotalToken) => {
        const userId = auth.currentUser?.uid;
        if (!userId) {
            console.error("User is not authenticated.");
            return;
        }

        const userTokensRef = doc(db, 'users', userId);
        try {
            await updateDoc(userTokensRef, {
                'tokens.usedDailyAllowance': increment(mentorTotalToken),
                'tokens.usedTotalAllowance': increment(mentorTotalToken)
            });
        } catch (error) {
            console.error('Error updating token allowances:', error);
        }
    };
    // **********************************************************************//


    // **********************************************************************//
    // the below function and hook is for fetching and updating token allowances in the context

    const fetchAndUpdateTokenAllowances = async () => {
        const userId = auth.currentUser?.uid;
        if (!userId) {
            console.error("Cannot update token allowance: No authenticated user.");
            return;
        }

        try {
            const tokens = await fetchUserTokenAllowance(userId);
            if (tokens) {
                setTokenAllowances({
                    dailyAllowance: tokens.dailyAllowance,
                    usedDailyAllowance: tokens.usedDailyAllowance,
                    totalAllowance: tokens.staticDefaultAllowance,
                    usedTotalAllowance: tokens.usedTotalAllowance,
                });
            }
        } catch (error) {
            console.error('Error fetching and updating token allowances:', error);
        }
    };

    useEffect(() => {
        const fetchAndUpdateTokensHook = async () => {
            await fetchAndUpdateTokenAllowances();
        };

        if (auth.currentUser) {
            fetchAndUpdateTokensHook();
        }
    }, [auth.currentUser]);
    // **********************************************************************//




const handleUserInput = async (message) => {
    const userId = auth.currentUser?.uid;
    const ticketId = uuidv4();
    setIsMessageBeingProcessed(true);

    try {
        const checkServerResponse = await fetch('/api/processTicket', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ userId, ticketId })
        });

        if (!checkServerResponse.ok) {
            throw new Error(`HTTP error! Status: ${checkServerResponse.status}`);
        }

        const serverAvailability = await checkServerResponse.json();
        if (serverAvailability.status === 'no go') {
            setIsServerBusyModalOpen(true); // Open the modal
            return;
        } else if (serverAvailability.status === 'wait') {
            const currentTime = new Date();
            const waitTime = new Date(serverAvailability.goTime).getTime() - currentTime.getTime();

            return new Promise((resolve) => {
                setTimeout(async () => {
                    await processAfterWait(message, userId); // Proceed after wait time
                    resolve();
                }, waitTime);
            });
        } else if (serverAvailability.status === 'go') {
            await processAfterWait(message, userId); // Proceed immediately if status is 'go'
        }
    } catch (error) {
        console.error('Error:', error);
    }
    finally {
        setIsMessageBeingProcessed(false);
    }
};




    // spaceeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee
    const processAfterWait = async (message, userId) => {
        let newAccumulatedResponses = accumulatedResponses.trim() ? `${accumulatedResponses}\nUser said: ${message}` : `User said: ${message}`;
        // Assume getTheToken function fetches a Firebase or API token
        const firebaseToken = await getTheToken();

        const userMessage = message // Assuming userMessage is already defined

        setIsWaitingAgentForResponse(true);

        setChatMessages(prevMessages => [...prevMessages, { agent: 'User', userMessage }]);

        setIsMessageBeingProcessed(false);

        if (newAccumulatedResponses.split(' ').length > 300) {
            try {
                const response = await fetch('/api/summarizer', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${firebaseToken}`,
                    },
                    body: JSON.stringify({ text: newAccumulatedResponses }),
                });
                if (!response.ok) throw new Error('Summarizer API failure');
                const data = await response.json();
                newAccumulatedResponses = data.summarizedText;
            } catch (error) {
                console.error("Error during summarization:", error);
            }
        }


        
        // Update the context state with the new accumulated responses
        setAccumulatedResponses(newAccumulatedResponses);

        const dataToSend = {
            agent: selectedAgent,
            caseDetails: {
                Theme: caseDetails.Theme,
                incident_description: caseDetails.incident_description,

            },
            selectedScenario: {
                what_happened: selectedScenario.what_happened,
                description: selectedScenario.description,
                root_cause: selectedScenario["root_cause(s)"],
                contributing_factors: selectedScenario.contributing_factors.join(", "),
                recommendations: selectedScenario.recommendations.map(recommendation => `${recommendation.action}: ${recommendation.description}`).join("; ")
            },
            message: message,
            newAccumulatedResponses: newAccumulatedResponses,
            Agents: agents,
            updatedInteractions: userAndAgentsInteractions,
        };

        const dataToSendToMentor = {
            theme: caseDetails.Theme,
            selectedScenario: {
                what_happened: selectedScenario.what_happened,
                description: selectedScenario.description,
                root_cause: selectedScenario["root_cause(s)"],
                contributing_factors: selectedScenario.contributing_factors.join(", "),
                recommendations: selectedScenario.recommendations.map(recommendation => `${recommendation.action}: ${recommendation.description}`).join("; ")
            },
        };

        // Update context state for dataToSendToMentor
        setDataToSendToMentor(dataToSendToMentor);

        const response = await fetch('/api/agents', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${firebaseToken}`, // Include the token
            },
            body: JSON.stringify(dataToSend),
        });
        if (response.status === 200) {
            setCalls(prevCalls => prevCalls + 1);

            let responseBody;
            try {
                responseBody = await response.json(); // Directly attempt to parse JSON from the response

                const gptResponse = responseBody.gpt_response;

                // Define a new chat record for Firestore including detailed info
                const newChatSet = {
                    userMessage: userMessage,
                    agent: selectedAgent,
                    geminiResponse: responseBody.gemini_response,
                    gptResponse: gptResponse,
                    geminiTokenUsed: responseBody.gemini_token,
                    gptTokenUsed: responseBody.gpt_token,
                    AgentTokenUsed: responseBody.gemini_token + responseBody.gpt_token,
                    timestamp: new Date().toISOString(),
                };

                setChatMessages(prevMessages => [...prevMessages, { agent: selectedAgent, gptResponse: gptResponse }]);
                setIsWaitingAgentForResponse(false);

                const serverResponse = `${selectedAgent} said: ${responseBody?.gpt_response}`;
                setAccumulatedResponses((prevResponses) => prevResponses.trim() ? `${prevResponses}\n${serverResponse}` : `${serverResponse}`);

                await updateSessionInFirestore(currentSessionId, newChatSet, newAccumulatedResponses);

                // Update the token allowances in Firestore
                await updateTokenAllowancesInFirestore(newChatSet.AgentTokenUsed);
                await fetchAndUpdateTokenAllowances();

            } catch (error) {
                console.error('Failed to process response:', error);
            }
        }
    };

    // mentor function =====================================================================
    const Mentor = async () => {
        // Ensuring a session is active; otherwise, log and exit
        if (!chatActive) {
            console.error("Chat is not active. Mentor feedback cannot be processed.");
            setIsMentorCalculating(false);
            return;
        }

        // Use a promise to handle the currentUser check asynchronously
        const user = await new Promise(resolve => {
            const unsubscribe = auth.onAuthStateChanged(user => {
                unsubscribe(); // Immediately unsubscribe once we get the user state
                resolve(user);
            });
        });

        if (!user) {
            console.error("No authenticated user available.");
            setIsMentorCalculating(false); // Update UI state as necessary
            return;
        }

        const firebaseToken = await getTheToken();
        setIsMentorCalculating(true);


        const parseFeedback = (feedbackText) => {
            try {
                let feedbackObj;
                try {
                    // Attempt to repair the JSON string first
                    const repairedFeedbackText = jsonrepair(feedbackText);
                    feedbackObj = JSON.parse(repairedFeedbackText);
                } catch (error) {
                    // Fallback for partial JSON or extraction
                    const jsonPart = feedbackText.match(/{.*}/s);
                    if (!jsonPart) {
                        throw new Error("No JSON content found in the feedback.");
                    }
                    // Attempt to repair the extracted JSON part
                    const repairedJsonPart = jsonrepair(jsonPart[0]);
                    feedbackObj = JSON.parse(repairedJsonPart);
                }
                const feedbackDetails = feedbackObj.RCAStepsFeedback;
                return { feedbackDetails };
            } catch (error) {
                console.error("Error parsing feedback:", error);
                return null;
            }
        };

        // Assuming 'currentSessionId' is available and correctly identifies the current session
        const updateFeedbackAndScore = async (parsedFeedback) => {
            if (!parsedFeedback) return;

            try {
                const { feedbackDetails, totalScore } = parsedFeedback;
                // Assuming these setters might involve async operations (e.g., fetching additional data before updating)
                setMentorFeedbackDetails(feedbackDetails);
                setMentorTotalScore(totalScore);
            } catch (error) {
                console.error("Error updating feedback in UI:", error);
            }
        };

        if (calls % 5 === 0 && calls > 0) {
            const payload = {
                selectedScenario: {
                    title: selectedScenario.title,
                    what_happened: selectedScenario.what_happened,
                    description: selectedScenario.description,
                    root_cause: selectedScenario["root_cause(s)"],
                    contributing_factors: selectedScenario.contributing_factors.join(", "),
                    recommendations: selectedScenario.recommendations.map(recommendation => `${recommendation.action}: ${recommendation.description}`).join("; ")
                },
                userAndAgentsInteractions: userAndAgentsInteractions.map(interaction => ({
                    ...interaction,
                    type: interaction.type || "default type"
                })),
                ...(feedbackCount > 0 && { previousFeedback: mentorFeedbackDetails })
            };

            try {
                const response = await fetch('/api/mentorAgent', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${firebaseToken}`,
                    },
                    body: JSON.stringify(payload),
                });
                console.log('payload in context for mentor0000000:', payload);
                const responseBody = await response.json();
                if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
                const feedbackText = responseBody.mentor_gpt_response;
                const parsedFeedback = parseFeedback(feedbackText);

                // Update feedback details  in context and Firestore
                await updateFeedbackAndScore(parsedFeedback);

                const uniqueId = uuidv4();

                const newMentorSet = {
                    id: uniqueId,
                    mentor_gemini_response: responseBody.mentor_gemini_response,
                    mentor_gemini_token: responseBody.mentor_gemini_token,
                    mentor_gpt_response: responseBody.mentor_gpt_response,
                    mentor_gpt_token: responseBody.mentor_gpt_token,
                    timestamp: new Date().toISOString(),
                    totalMentorToken: responseBody.mentor_gemini_token + responseBody.mentor_gpt_token,
                };

                // Make a second API call to scoringAgent.js with selectedScenario and feedbackText
                const scoringResponse = await fetch('/api/scoringAgent', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${firebaseToken}`,
                    },
                    body: JSON.stringify({ selectedScenario, feedbackText }),
                });

                const scoringResponseBody = await scoringResponse.json();
                if (!scoringResponse.ok) throw new Error(`HTTP error! Status: ${scoringResponse.status}`);

                // Assuming scoringResponseBody.mentorScoringRaw is a string containing the raw JSON
                const repairedMentorScoringRaw = jsonrepair(scoringResponseBody.mentorScoringRaw);
                const mentorScoringParsed = JSON.parse(repairedMentorScoringRaw);

                // Construct newMentorScoringSet as previously outlined -
                //
                const newMentorScoringSet = {
                    id: uniqueId,
                    mentorScoring: mentorScoringParsed.RCAStepsFeedback,
                    timestamp: new Date().toISOString(),
                };

                // Now call updateMentorScoringSets.. to compare and update
                await updateMentorScoringSets(currentSessionId, newMentorScoringSet);
                console.log('newMentorScoringSet in context:', newMentorScoringSet);

                // Update session with new mentor feedback
                await updateMentorSessionInFirestore(currentSessionId, newMentorSet);

                // Update token allowances
                await updateMenotrTokenAllowancesInFirestore(newMentorSet.totalMentorToken);

                setFeedbackCount(feedbackCount + 1);
                await fetchAndUpdateTokenAllowances();

            } catch (error) {
                console.error("Error fetching mentor feedback:", error);
            }
        }
        setIsMentorCalculating(false);
    };

    useEffect(() => {
        if (calls) {
            Mentor();
        }
    }, [calls]);

    // *****************************************************************************************//
    // Assuming this is placed within the Mentor function or called immediately after creating newMentorScoringSet
    const updateMentorScoringSets = async (currentSessionId, newMentorScoringSet) => {
        const sessionRef = doc(db, 'sessions', currentSessionId);
        const sessionSnap = await getDoc(sessionRef);

        if (!sessionSnap.exists()) {
            console.error("Session not found");
            return;
        }

        let existingMentorScoringSets = sessionSnap.data().mentorScoringSets || [];
        let highestScores = {}; // Object to hold the highest scores across categories

        if (existingMentorScoringSets.length > 0) {
            existingMentorScoringSets.forEach(set => {
                Object.keys(set.mentorScoring).forEach(key => {
                    if (!highestScores[key] || highestScores[key].Score < set.mentorScoring[key].Score) {
                        highestScores[key] = set.mentorScoring[key]; // Update if current set has a higher score
                    }
                });
            });
        }

        // Compare newMentorScoringSet scores with highestScores and update if new is higher
        Object.keys(newMentorScoringSet.mentorScoring).forEach(key => {
            if (!highestScores[key] || highestScores[key].Score < newMentorScoringSet.mentorScoring[key].Score) {
                highestScores[key] = newMentorScoringSet.mentorScoring[key]; // Update highestScores with new high scores
            }
        });

        // Prepare the updated scoring set to add back to Firestore
        const updatedMentorScoringSet = {
            ...newMentorScoringSet,
            mentorScoring: highestScores
        };

        // Update Firestore with the updated scoring set
        await updateDoc(sessionRef, {
            mentorScoringSets: arrayUnion(updatedMentorScoringSet)
        });
    };
    // *****************************************************************************************//

    // *****************************************************************************************//

    const parseFeedback2 = (feedbackText) => {
        try {
            // Attempt to repair the JSON string first using jsonrepair
            const repairedFeedbackText = jsonrepair(feedbackText);

            // Directly parse the repaired JSON string to get the mentor_gpt_response object
            const feedbackObj = JSON.parse(repairedFeedbackText);

            // Access the RCAStepsFeedback object directly since feedbackObj is now a JS object
            const RCAStepsFeedback = feedbackObj["RCAStepsFeedback"];

            // Return both the detailed feedback
            return { feedbackDetails: RCAStepsFeedback };
        } catch (error) {
            console.error("Error parsing feedback:", error);
            return null; // Return null to indicate failure in parsing
        }
    };


    // Assuming 'currentSessionId' is available and correctly identifies the current session
    const updateFeedbackAndScore2 = async (parsedFeedback) => {
        if (!parsedFeedback) return;

        try {
            const { feedbackDetails, totalScore } = parsedFeedback;
            // Assuming these setters might involve async operations (e.g., fetching additional data before updating)
            setMentorFeedbackDetails(feedbackDetails);
            setMentorTotalScore(totalScore);
        } catch (error) {
            console.error("Error updating feedback in UI:", error);
        }
    };





    // reset ================================================================================

    const reset = async () => {
        // Optionally, update Firestore to indicate the session is inactive or reset session data
        if (currentSessionId) {
            const sessionDocRef = doc(db, "sessions", currentSessionId);
            try {
                await updateDoc(sessionDocRef, {
                    // Set any flags or data fields to indicate the session is reset/unloaded
                    isActive: false, // Example flag, adjust based on your app's needs
                    lastUpdated: serverTimestamp(),
                });
            } catch (error) {
                console.error("Error marking session as inactive in Firestore:", error);
            }
        }

        // Reset state hooks to their initial states
        setChatMessages([]);
        setAgents([]);
        setMessage('');
        setIncidentDescription('');
        setAccumulatedResponses('');
        setCalls(0);
        setSelectedAgent(null);
        setCaseDetails({});
        setSelectedScenario({});
        setTotalScore(0);
        setParsedFeedback({});
        setFeedbackCount(0);
        setFeedbackDetails({});
        setMentorFeedbackDetails({});
        setMentorTotalScore(0);
        localStorage.removeItem('selectedTheme');
        localStorage.removeItem('setAgents');

        setChatActive(false);
        setCurrentSessionId(null);
        localStorage.removeItem('currentSessionId');
        setCurrentSession(null);
        setChatSets([]);
        setMentorSets([]);
        setMentorScoringSets([]);
    };



    useEffect(() => {
        if (!isLoggedIn) {
            reset();
        }
    }, [isLoggedIn]);
    

const logout2 = async () => {
    try {
        // Then sign out the user
        await signOut(auth);

        // Reset the context after the user is signed out
        reset();
    } catch (error) {
        console.error("Sign out error", error);
    }
};

    const value = {
        // other context values,
        logout2,
    };

    return (
        <AppContext.Provider value={{
            value,
            isLoggedIn,
            selectedAgent,
            setSelectedAgent,
            chatMessages,
            setChatMessages,
            caseDetails,
            handleUserInput,
            agents,
            setAgents,
            message,
            setMessage,
            setIncidentDescription,
            setCaseDetails,
            calls,
            setCalls,
            logout2,
            mentorFeedbackDetails,
            setMentorFeedbackDetails,
            totalScore,
            setTotalScore,
            dataToSendToMentor,
            userAndAgentsInteractions,
            accumulatedResponses,
            setAccumulatedResponses,
            isMentorCalculating,
            setIsMentorCalculating,
            feedbackDetails,
            setFeedbackDetails,
            mentorTotalScore,
            setMentorTotalScore,
            isGuideVisible,
            setIsGuideVisible,
            sessions,
            addSession,
            removeSession,
            autosave,
            setAutoSave,
            handleManualSave,
            saveStatus,
            loadSession,
            updateSessionName,
            firebaseToken,
            setFirebaseToken,
            chatActive,
            setChatActive,
            currentSessionId,
            setCurrentSessionId,
            currentSession,
            setCurrentSession,
            setSessions,
            mentorCalculating,
            syncSessionWithFirestore,
            selectedTheme,
            reset,
            gptResponse,
            tokenAllowances,
            setIsWaitingAgentForResponse,
            isWaitingAgentForResponse,
            setShowGuide,
            showGuide,
            setIsCreateSessionVisible,
            isCreateSessionVisible,
            isMessageBeingProcessed,
            setIsMessageBeingProcessed,
            isServerBusyModalOpen, 
            setIsServerBusyModalOpen

        }}>
            {children}
        </AppContext.Provider>
    );
};
