import create from 'zustand';
import { applyNodeChanges, applyEdgeChanges, addEdge } from 'reactflow';
import { getFirestore, collection, doc, setDoc, getDoc } from 'firebase/firestore';
import { nodes as initialNodes, edges as initialEdges } from './Components/fishbone/fish-elements';

const firestore = getFirestore();

const validateNodes = (nodes) => {
    if (!Array.isArray(nodes)) {
        return []; // Return an empty array or sensible default to recover gracefully
    }

    return nodes.map(node => {
        if (!node.position || node.position.x === undefined || node.position.y === undefined) {
            return { ...node, position: { x: 0, y: 0 } };
        }
        return node;
    });
};

const validateEdges = (edges) => {
    if (!Array.isArray(edges)) return [];
    return edges; // Add any needed validation here
};

const useStore = create((set, get) => ({
    nodes: validateNodes(initialNodes),
    edges: initialEdges,
    setNodes: (newNodes) => set({ nodes: validateNodes(newNodes) }),

    onEdgesChange: (changes) => set((state) => {
        const updatedEdges = state.edges.map(edge => {
            const change = changes.find(c => c.id === edge.id);
            return change ? { ...edge, ...change } : edge;
        });
        return { ...state, edges: updatedEdges };
    }),
    addNode: (node) => set((state) => ({ nodes: [...state.nodes, node] })),
    setEdges: (edgesOrUpdaterFunction) => set((state) => {
        const newEdges = typeof edgesOrUpdaterFunction === 'function'
            ? edgesOrUpdaterFunction(state.edges)
            : validateEdges(edgesOrUpdaterFunction); // Ensure validation is applied here as well
        return { ...state, edges: newEdges };
    }),

    updateNodePosition: (nodeId, newPosition) => set((state) => {
        const nodeIndex = state.nodes.findIndex(node => node.id === nodeId);
        if (nodeIndex === -1) return state; // Node not found, no update needed

        const node = state.nodes[nodeIndex];
        if (node.position.x === newPosition.x && node.position.y === newPosition.y) {
            return state; // Position hasn't changed, no update needed
        }

        const updatedNode = { ...node, position: newPosition };
        const updatedNodes = [...state.nodes];
        updatedNodes[nodeIndex] = updatedNode;

        return { ...state, nodes: updatedNodes };
    }),

    setNodes: (updateFunction) => set(state => {
        const updatedNodes = updateFunction(state.nodes);
        return { ...state, nodes: validateNodes(updatedNodes) };
    }),

    removeNode: (nodeId) => {
        console.log("Removing node from store was called:", nodeId);

        return set((state) => ({
            nodes: state.nodes.filter(node => node.id !== nodeId),
            edges: state.edges,
        }));
    },

    removeEdge: (edgeId) => {
        console.log("Removing edge from store was called:", edgeId);

        return set((state) => ({
            nodes: state.nodes,
            edges: state.edges.filter(edge => edge.id !== edgeId),
        }));
    },

    onNodesChange: (changes) => set((state) => ({
        nodes: applyNodeChanges(changes, state.nodes),
    })),

    onConnect: (params) => set((state) => ({
        edges: addEdge(params, state.edges),
    })),

    updateNodeText: (nodeId, text) => set((state) => ({
        nodes: state.nodes.map((node) => node.id === nodeId ? { ...node, data: { ...node.data, label: text } } : node),
    })),

    updateEdgeStroke: (edgeId, strokeColor) => set((state) => ({
        edges: state.edges.map((edge) => edge.id === edgeId ? { ...edge, style: { ...edge.style, stroke: strokeColor } } : edge),
    })),

    selectedNode: null, // Initialize selectedNode as null
    selectedEdge: null, // Initialize selectedEdge as null
    setSelectedNode: (node) => {
        set((state) => ({ ...state, selectedNode: node }));
    },
    setSelectedEdge: (edge) => {
        set((state) => ({ ...state, selectedEdge: edge }));
    },
    clearSelection: () => set({ selectedNode: null, selectedEdge: null }),

    saveFlow: async (userId, flowName, flowSerialNumber) => {
        const { nodes, edges } = get();
        await setDoc(doc(collection(doc(collection(firestore, "users"), userId), "flows"), flowSerialNumber), {
            name: flowName,
            data: { nodes, edges },
        });
    },

    loadFlow: async (userId, flowSerialNumber) => {
        const docSnap = await getDoc(doc(collection(doc(collection(firestore, "users"), userId), "flows"), flowSerialNumber));
        if (docSnap.exists()) {
            const { nodes, edges } = docSnap.data().data;
            set({ nodes: validateNodes(nodes), edges: validateEdges(edges) });
        } else {
            console.log("No such document!");
        }
    },

    updateNodeColor: (nodeId, newColor) => set((state) => {
        const updatedNodes = state.nodes.map(node => {
            if (node.id === nodeId) {
                return { ...node, data: { ...node.data, color: newColor } };
            }
            return node;
        });
        return { ...state, nodes: updatedNodes };
    }),

    updateEdgeColor: (edgeId, color) => set((state) => ({
        edges: state.edges.map((edge) => {
            if (edge.id === edgeId) {
                return { ...edge, style: { ...edge.style, stroke: color } };
            }
            return edge;
        }),
    })),

    updateEdgeStyle: (edgeId, newType, newStyle, animated) => set((state) => {
        console.log(`Updating edge: ${edgeId}, Type: ${newType}, Animated: ${animated}`, 'New Style:', newStyle);

        const updatedEdges = state.edges.map(edge => {
            if (edge.id === edgeId) {
                const updatedEdge = { ...edge, type: newType, animated: animated, style: { ...edge.style, ...newStyle } };
                console.log('Before Update:', edge);
                console.log('After Update:', updatedEdge);
                return updatedEdge;
            }
            return edge;
        });

        return { ...state, edges: updatedEdges };
    }),

    editingNodeId: null,
    editingText: "",
  
    // Actions to manage editing state
    startEditingNode: (nodeId, text) => set({ editingNodeId: nodeId, editingText: text }),
    updateEditingText: (text) => set({ editingText: text }),
    stopEditing: () => set({ editingNodeId: null, editingText: "" }),
  
    updateNodeLabel: (nodeId, newLabel) => set((state) => {
        const node = state.nodes.find((node) => node.id === nodeId);
        if (!node) {
            return;
        }

        const updatedNode = { ...node, data: { ...node.data, label: newLabel } };
        console.log(updatedNode);

        const newNodes = state.nodes.map((node) => node.id === nodeId ? updatedNode : node);
        return { nodes: newNodes };
    }),

    updateNodeSize: (nodeId, newWidth, newHeight) => set((state) => {
        console.log('Updating node size:', nodeId, newWidth, newHeight); // Add this line

        const node = state.nodes.find((node) => node.id === nodeId);
        if (!node) {
            console.log('Node not found:', nodeId); // Add this line
            return;
        }

        // Assuming width and height are supposed to be directly under node.data
        const updatedNode = {
            ...node,
            data: {
                ...node.data,
                width: newWidth,
                height: newHeight
            }
        };

        console.log('Updated node:', updatedNode); // Add this line

        const newNodes = state.nodes.map((node) => node.id === nodeId ? updatedNode : node);

        console.log('New nodes:', newNodes); // Add this line

        return { nodes: newNodes };
    }),

    // Add this function to your store
    updateNodeTextColor: (nodeId, color) => {
        set((state) => {
            const nodeIndex = state.nodes.findIndex((node) => node.id === nodeId);
            if (nodeIndex > -1) {
                state.nodes[nodeIndex].data.textColor = color;
            }
        });
    },
  
}));

export default useStore;
