import React, { useState, useCallback } from 'react';
import { DndProvider, useDrag } from 'react-dnd';
import './fishCss.css';

const DraggableMenuItem = ({ item }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'MENU_ITEM',
    item: { id: item.id, type: item.text }, // Include item.type to identify the item being dragged
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }), [item]);

  // Opacity changes when dragging
  const opacity = isDragging ? 0.4 : 1;

  return (
    <div ref={drag} style={{
      backgroundColor: '#f0f0f0',
      marginBottom: '5px',
      padding: '10px',
      borderRadius: '5px',
      cursor: 'move',
      opacity, // Apply opacity
    }}>
      {item.text}
    </div>
  );
};

const MyMenu = () => {
  const [position, setPosition] = useState({ x: 200, y: 100 });

  // Define the menuItems array within the MyMenu component
  const menuItems = [
    { id: 1, text: 'Level 1', className: 'menu-drag-handle' },
    { id: 2, text: 'Level 2', border: '1px solid #5B84B1' },
    { id: 3, text: 'Level 3', border: '1px solid #E69A8D' },
  ];

  const startDrag = useCallback((e) => {
    if (e.target.className === "menu-drag-handle") {
      const startX = e.clientX - position.x;
      const startY = e.clientY - position.y;

      const onMove = (moveEvent) => {
        setPosition({
          x: moveEvent.clientX - startX,
          y: moveEvent.clientY - startY,
        });
      };

      const onUp = () => {
        window.removeEventListener('mousemove', onMove);
        window.removeEventListener('mouseup', onUp);
      };

      window.addEventListener('mousemove', onMove);
      window.addEventListener('mouseup', onUp);
    }
  }, [position]);

  return (

      <div style={{ position: 'absolute', top: position.y, left: position.x, width: '200px', border: '1px solid #ccc', borderRadius: '5px', background: '#fff' }}>
        <div 
            className="menu-drag-handle" 
            onMouseDown={startDrag} 
            style={{ 
                backgroundColor: '#ddd', 
                padding: '6px', 
                cursor: 'move', 
                borderTopLeftRadius: '5px', 
                borderTopRightRadius: '5px', 
                textAlign: 'center', 
                fontWeight: 'bold' 
            }}
        >
            <div style={{ 
                width: '20px', 
                borderBottom: '2px solid gray', 
                margin: '2px auto' 
            }}></div>
            <div style={{ 
                width: '20px', 
                borderBottom: '2px solid gray', 
                margin: '2px auto' 
            }}></div>
            <div style={{ 
                width: '20px', 
                borderBottom: '2px solid gray', 
                margin: '2px auto' 
            }}></div>
        </div>
        <div style={{ padding: '10px' }}>
          {menuItems.map((item) => (
            <DraggableMenuItem key={item.id} item={item} />
          ))}
        </div>
      </div>

  );
};

export default MyMenu;
