import 'tailwindcss/tailwind.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App.js';
import { onAuthStateChanged } from "firebase/auth";
import { auth } from './firebase-config';

class ErrorBoundary extends React.Component {
  state = { hasError: false, userToken: null };

  componentDidMount() {
    onAuthStateChanged(auth, user => {
      if (user) {
        user.getIdToken().then(token => {
          this.setState({ userToken: token });
        });
      }
    });
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    if (this.state.userToken) {
      fetch('/api/logError', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.state.userToken}`
        },
        body: JSON.stringify({
          error: error.toString(),
          errorInfo: errorInfo ? errorInfo.componentStack : null
        })
      });
    }
    // No console.log here to ensure compliance with no logs on user side
  }

  render() {
    if (this.state.hasError) {
      // Optionally, render a custom fallback UI
      return <h1>Something went wrong.</h1>;
    }
    return this.props.children;
  }
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </React.StrictMode>
);

