import { auth, db } from "./firebase-config";
import { setDoc, doc, serverTimestamp, increment, updateDoc} from "firebase/firestore";
import { sendPasswordResetEmail as firebaseSendPasswordResetEmail } from "firebase/auth";

import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
} from "firebase/auth";

export const signUp = async (email, password) => {
  const userCredential = await createUserWithEmailAndPassword(auth, email, password);

  // Fetch the Firebase auth token
  const token = await userCredential.user.getIdToken(true);

  // Add a Firestore document for the user
  await setDoc(doc(db, "temp", userCredential.user.uid), {
    email: userCredential.user.email,
    preferences: {
      autosave: true, 
      showGuide: true,
    },
    tokens: {
      staticDefaultAllowance: 500000,
      dailyAllowance: 100000,
      usedDailyAllowance: 0,
      usedTotalAllowance: 0,
    },
    createdAt: serverTimestamp(), // Firestore server timestamp for when the user was created
  });

  return userCredential;
};

export const sendVerification = async (user) => {
  try {
    await sendEmailVerification(user);
    console.log("Verification email sent.");

    // Add a document to the 'verificationCount' collection
    await setDoc(doc(db, "verificationCount", user.uid), {
      email: user.email,
      count: 1, // Initialize count to 1
      timestamp: serverTimestamp(), // Add a server timestamp
    }, { merge: true }); // Use the 'merge' option to increment the count if the document already exists
  } catch (error) {
    console.error("Error sending verification email:", error);
  }
};

export const resendVerification = async () => {
  try {
    const user = auth.currentUser;
    if (user) {
      await sendEmailVerification(user);
      console.log("Verification email resent.");

      // Increment the count in the 'verificationCount' document
      await updateDoc(doc(db, "verificationCount", user.uid), {
        count: increment(1), // Increment the count by 1
        timestamp: serverTimestamp(), // Update the timestamp
      });

      return true; // Return true to indicate that the verification email was successfully sent
    } else {
      console.error("No user is logged in.");
      return false; // Return false to indicate that the verification email was not sent because no user is logged in
    }
  } catch (error) {
    console.error("Error resending verification email:", error);
    return false; // Return false to indicate that the verification email was not sent due to an error
  }
};

export const logIn = async (email, password) => {
  const userCredential = await signInWithEmailAndPassword(auth, email, password);
  const token = await userCredential.user.getIdToken(true); // Get the Firebase auth token
  return userCredential;
};

export const sendPasswordResetEmail = async (email) => {
  try {
    await firebaseSendPasswordResetEmail(auth, email);
    console.log('Password reset email sent successfully');
  } catch (error) {
    console.error('Error in sending password reset email: ', error);
    throw error;
  }
};