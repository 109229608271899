import React, { useRef, useEffect, useState, useContext } from 'react';
import 'tailwindcss/tailwind.css';
import { AppContext } from './localAPI/context.js';
import { signOut } from 'firebase/auth';
import '../animations/animations.css';
import { auth, db } from '../firebase-config.js';
import { useFetchUserSessions } from './localAPI/useFetchUserSessions.js';


function Chat({ selectedTheme, onReset }) {
  const textareaRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeAgent, setActiveAgent] = useState(null);
  const { reset, logout2, handleManualSave, saveStatus, chatActive, isServerBusyModalOpen, setIsServerBusyModalOpen, isMessageBeingProcessed, setChatActive, chatMessages, handleUserInput, agents, setSelectedAgent, message, isWaitingAgentForResponse, tokenAllowances, setMessage, isMentorCalculating, mentorFeedbackDetails, mentorTotalScore, caseDetails } = useContext(AppContext);
  const lastMessageRef = useRef(null);

  const [isModalresetOpen, setIsModalresetOpen] = useState(false);
  const [isModalsendOpen, setIsModalsendOpen] = useState(false);
  const [isAwaitingResponse, setIsAwaitingResponse] = useState(false);

  // Determine button label and class based on saveStatus
  const buttonLabel = saveStatus ? saveStatus : 'Save';
  const buttonClass = saveStatus === 'Saved' ? 'bg-green-500' : 'bg-green-400';


  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  const sendMessage = () => {
    if (isAwaitingResponse || isMentorCalculating) {
      return;
    }

    if (!activeAgent) {
      setIsModalsendOpen(true);
      return;
    }
    if (message.trim() !== '') {
      setIsAwaitingResponse(true); 
      handleUserInput(message).then(() => {
        setIsAwaitingResponse(false); 
      }).catch((error) => {
        console.error('Error during message sending:', error);
        setIsAwaitingResponse(false); 
      });
      setMessage('');
      textareaRef.current.style.height = 'auto';
    }
  };

  useEffect(() => {
    const textarea = textareaRef.current;
    const setHeight = () => {
      textarea.style.height = 'auto';
      if (textarea.scrollHeight <= 60) {
        textarea.style.height = `${textarea.scrollHeight}px`;
      } else {
        textarea.style.height = '60px';
      }
    };
    setHeight();
    textarea.addEventListener('input', setHeight);
    return () => {
      textarea.removeEventListener('input', setHeight);
    };
  }, []);


  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chatMessages]);

  const resetChat = () => {
    setIsModalresetOpen(true);
  };

  const handleConfirmReset = () => {
    reset();
    setChatActive(false); 
    localStorage.setItem('chatActive', 'false'); 
    setIsModalresetOpen(false);
  };

const logout = async () => {
    try {
      logout2();

        reset();

        // Ensure onReset is defined and is a function
        if (typeof onReset === 'function') {
            onReset();
        }
        localStorage.removeItem('recaptchaToken');
    } catch (error) {
        console.error("Sign out error", error);
    }
};

  const renderFeedbackDetails = (mentorFeedbackDetails) => {
    return Object.entries(mentorFeedbackDetails).map(([step, detail], index) => {
      if (detail && detail.feedback) {
        return (
          <div key={index} className="mb-2">
            <h3 className="font-semibold">{step}</h3>
            <p>{detail.feedback}</p> {/* Display only the feedback part */}
          </div>
        );
      }
      return null; 
    });
  };

  return (
    <div className="Chat">
      <div className="flex md:flex-row h-screen">

        {/* The is the Left sided Case Details Menu Main */}
        <div className={`fixed z-50 h-full overflow-auto bg-white transform top-0 left-0 w-full md:w-1/4 transition-transform duration-200 ease-in-out ${isMenuOpen ? 'translate-x-0' : '-translate-x-full'} md:translate-x-0 md:static md:overflow-visible`}>

          {/* LEft sided menu header */}
          <div className="bg-gray-200 pt-5 pb-2">
            <div></div>
            <h1 className="text-xs sm:text-sm md:text-base text-center font-bold text-2xl">Case Details</h1>
          </div>
          {caseDetails && (
            <div className="text-xs sm:text-sm md:text-base p-4">
              <h2 className="font-bold">{caseDetails.Theme}</h2>
              <p>{caseDetails.incident_description}</p>
            </div>
          )}

          {/* Mentor Feedback area */}
          <div className="overflow-y-auto h-[70%]">
            {isMentorCalculating ? (
              <div className="mentor-feedback-loader ">
                <div className="loader-mentor">Getting Mentor Feedback...</div>
              </div>
            ) : (
              mentorFeedbackDetails && (
                <div className="text-xs sm:text-sm md:text-base mentor-feedback-section p-4">
                  <h2 className="font-bold">Mentor Feedback</h2>
                  {renderFeedbackDetails(mentorFeedbackDetails)}
                </div>
              )
            )}
          </div>
          {/* This is for the mobile case menu to close it */}
          <button onClick={() => setIsMenuOpen(false)} className="md:hidden p-4 absolute top-0 right-0 z-10" aria-label="Close menu">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-8 w-8">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        {/* Mobile Case Menu Toggle Button */}
        <div className={`mobile-case-menu-toggle ${isMenuOpen ? 'hidden' : ''} md:hidden absolute z-10`} style={{ top: '15vh', left: 0 }}>
          <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="w-8 h-24 flex items-center justify-center opacity-80">
            <img src={`${process.env.PUBLIC_URL}/feedback.svg`} alt="Feedback" className="h-full w-auto" />
          </button>

        </div>

        {/* Mobile Case Details Menu */}
        {isMenuOpen && caseDetails && (
          <div className="mobile-case-details absolute z-10 bg-gray-200 w-64 h-full overflow-auto" style={{ top: 0, left: 0 }}>
            <h2 className="font-bold">{caseDetails.Theme}</h2>
            <p>{caseDetails.incident_description}</p>
            {/* Close button, other menu content goes here */}
          </div>
        )}

        {/* this is the right sided chat area */}
        <div className="w-full md:3-3/4 bg-gradient-to-r from-gray-100 via-white to-gray-100 flex flex-col">
          <div className="chat-interface pt-0 sm:pr-4 sm:pl-4 flex flex-col flex-grow overflow-auto w-full sm:w-auto">
            <div className="bg-gray-200 pt-2 pb-1 flex flex-col sm:flex-row justify-between items-center">
              <div></div>

              {/* Chat header title */}
              <div className="flex items-center justify-center mb-4 sm:mb-0">
                <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="Logo" className="h-12 w-auto mr-4" />
                <h1 className="text-center font-bold text-xl sm:text-2xl">RCA-AI</h1>
              </div>

              {/* Chat header buttons */}
              <div className="flex">
                <div className="allowance-display">
                  <button className="bg-gray-800 border text-white p-1 mr-4 rounded-lg grid grid-cols-2 items-center pointer-events-none shadow text-xs sm:text-xs md:text-base" disabled>
                    <div className="text-xs">
                      <div>Daily</div>
                      <div>Allowance:</div>
                    </div>
                    <span className="row-span-2">{((tokenAllowances.usedDailyAllowance || 0) / 1000).toLocaleString()}/{((tokenAllowances.dailyAllowance || 0) / 1000).toLocaleString()}</span>
                  </button>
                  {/* If you want to display total allowance as well */}
                  <button className="bg-gray-800 border text-white p-1 mr-4 rounded-lg grid grid-cols-2 items-center pointer-events-none shadow text-xs sm:text-xs md:text-base" disabled>
                    <div className="text-xs">
                      <div>Total</div>
                      <div>Allowance:</div>
                    </div>
                    <span className="row-span-2">{((tokenAllowances.usedTotalAllowance || 0) / 1000).toLocaleString()}/{((tokenAllowances.totalAllowance || 0) / 1000).toLocaleString()}</span>
                  </button>
                </div>
                <div className="mentor-total-score bg-gray-800 border text-white p-1 mr-4 rounded-lg flex items-center pr-2 pl-2 shadow text-xs sm:text-xs">
                  <div>
                    <div className="text-xs">Total Score:</div>
                    <div>{mentorTotalScore * 2}/100</div>
                  </div>
                  <div className="progress-bar-vertical ml-4">
                    <div className="progress-bar-fill" style={{ height: `${mentorTotalScore * 2}%` }}></div>
                  </div>
                </div>
                <button onClick={resetChat} className="bg-orange-400 hover:bg-red-800 shadow text-white p-2 mr-4 rounded-lg text-xs sm:text-xm">Reset</button>
              </div>
            </div>

            {isServerBusyModalOpen && (
              <div className="fixed z-50 inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                <div className="bg-white p-8 rounded-lg shadow">
                  <p className="text-center mb-4">Apologies, the server is too busy at the moment. Please try again later.</p>
                  <div className="flex justify-center mt-2">
                    <button onClick={() => setIsServerBusyModalOpen(false)} className="mr-6 bg-gray-200 px-4 py-2 rounded p-2">OK</button>
                  </div>
                </div>
              </div>
            )}


            {/* chat box area */}
            <div className="chat-box bg-white shadow-lg rounded-lg sm:pl-4 sm:pr-4 py-4 flex-grow flex flex-col justify-between" style={{ margin: '1rem 0 3vh' }}>
              <div className="text-xs sm:text-sm md:text-base dialogue-area overflow-y-auto p-2 rounded-lg m-2 max-h-[60vh]">
                {
                  chatMessages.flatMap((chatSet, index) => [
                    chatSet.userMessage ? (
                      <div key={`user-${chatSet.timestamp}-${index}`}
                        className="chat-bubble p-3 my-2 rounded-lg bg-blue-100 self-end">
                        <p><strong>User:</strong> {chatSet.userMessage}</p>
                      </div>
                    ) : null,
                    chatSet.gptResponse ? (
                      <div key={`agent-${chatSet.timestamp}-${index}`}
                        className="chat-bubble p-3 my-2 rounded-lg bg-gray-200"
                        ref={index === chatMessages.length - 1 ? lastMessageRef : null}>
                        <p><strong>{chatSet.agent}:</strong> {chatSet.gptResponse}</p>
                      </div>
                    ) : null
                  ]).filter(Boolean)
                }
                {isMessageBeingProcessed && (
                  <div className="chat-bubble p-3 my-2 rounded-lg loading-chat-bubble" ref={lastMessageRef}>
                    <div className="loader">Sending...</div>
                  </div>
                )}
                {isWaitingAgentForResponse && (
                  <div className="chat-bubble p-3 my-2 rounded-lg loading-chat-bubble" ref={lastMessageRef}>
                    <div className="loader">Loading...</div>
                  </div>
                )}
              </div>

              <div className="chat-inputs w-full mx-[1%] flex flex-col">
                <div className="agents-container flex overflow-x-auto py-2">
                  <div className="agent-block p-2 bg-white text-gray-800 font-bold text-sm sm:text-base">
                    Staff
                  </div>
                  <div className="agent-block p-2 mr-2 bg-white text-gray-800 text-xs sm:text-sm">
                    Select<br />One
                  </div>
                  {['Quality AI', ...agents].map((agent, index) => (
                    <button
                      key={index}
                      className={`agent-block border p-2 mr-2 rounded-lg shadow bg-gray-100 hover:bg-gray-600 hover:text-white ${activeAgent === agent ? 'bg-gray-800 text-white' : ''} text-xs sm:text-sm`}
                      onClick={() => {
                        setSelectedAgent(agent);
                        setActiveAgent(agent);
                      }}
                    >
                      {agent}
                    </button>
                  ))}
                </div>

                <div className="input-area flex items-center">
                  <label htmlFor="messageInput" className="sr-only">Type a message...</label>
                  <textarea id="messageInput" ref={textareaRef} className="border p-2 flex-grow overflow-hidden resize-none focus:outline-none rounded-lg focus:ring-2 focus:ring-gray-300 ml-2 sm:ml-0" rows="1" maxLength="150" placeholder="Type a message..." value={message} onChange={(e) => setMessage(e.target.value)} onKeyPress={handleKeyPress}></textarea>
                  <button
                    onClick={sendMessage}
                    disabled={isAwaitingResponse || isMentorCalculating}
                    className={`text-xs sm:text-sm md:text-base p-1 sm:p-2 ml-2 mr-2 sm:mr-6 shadow rounded-lg w-10 sm:w-20 ${(isAwaitingResponse || isMentorCalculating) ? 'bg-gray-500 cursor-not-allowed' : 'bg-orange-500 hover:bg-green-700 active:bg-blue-400'} text-white`}
                  >
                    <span className="sm:hidden">⏎</span>
                    <span className="hidden sm:inline">Send</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* chat footer area */}
          <div className='flex justify-between items-center mb-4 mt-0 pt-0'>
            <button
              onClick={handleManualSave}
              className={`${buttonClass} hover:bg-green-600 shadow text-white font-bold py-3 px-6 rounded-lg w-16 sm:w-20 ml-4 mt-0 flex items-center justify-center transition-colors duration-200 text-xs sm:text-sm md:text-base`}
            >
              {buttonLabel}
            </button>
            <p className="text-xxs sm:text-xxs md:text-sm lg:text-sm xl:text-md text-gray-600 text-center mt-0 pt-1 pl-1">
              Disclaimer: This is a prototype project, the answers are AI generated and may need verification. Your feedback iss appreciated
            </p>
            <button onClick={logout} className="bg-red-500 hover:bg-red-700 shadow text-white font-bold py-3 px-6 rounded-lg w-16 sm:w-20 mr-4 mt-0 flex items-center justify-center text-xs sm:text-sm md:text-base">
              Logout
            </button>
          </div>

        </div>



      </div>

      {/* popups modals */}
      {isModalresetOpen && (
        <div className="fixed z-50 inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-8 rounded-lg shadow">
            <p className="text-center mb-4">Are you sure you want to reset the chat. All details will not be saved. Are you sure?</p>
            <div className="flex justify-center mt-2">
              <button onClick={() => setIsModalresetOpen(false)} className="mr-6 bg-gray-200 px-4 py-2 rounded p-2">No</button>
              <button onClick={handleConfirmReset} className="ml-6 bg-red-500 text-white px-4 py-2 rounded p-2">Yes</button>
            </div>
          </div>
        </div>
      )}
      {isModalsendOpen && (
        <div className="fixed z-50 inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-8 rounded-lg shadow">
            <p className="text-center mb-4">Please select one of the Staff just above the message area and type your text before proceeding.</p>
            <div className="flex justify-center mt-2">
              <button onClick={() => setIsModalsendOpen(false)} className="mr-6 bg-gray-200 px-4 py-2 rounded p-2">OK</button>
            </div>
          </div>
        </div>
      )}
    </div>

  );

}

export default Chat;