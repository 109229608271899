import React, { useState, useEffect, useCallback } from 'react';
import './animations/animations.css';
import { signUp, logIn, sendVerification, resendVerification, sendPasswordResetEmail } from './AuthoService';
import { auth, db } from './firebase-config';
import { doc, getDoc } from 'firebase/firestore';
import { useFieldValidation } from './Components/misc/fieldsvalidate';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

function LandingPage({ onLogin, onClose }) {
    const [showForm, setShowForm] = useState(null);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const handleEmailChange = (e) => setEmail(e.target.value);
    const handlePasswordChange = (e) => setPassword(e.target.value);
    const handleNameChange = (e) => setName(e.target.value);
    const [loginError, setLoginError] = useState('');
    const [signupSuccess, setSignupSuccess] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [verificationCheckIntervalId, setVerificationCheckIntervalId] = useState(null);
    const [recaptchaError] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [recaptchaToken, setRecaptchaToken] = useState(null);
    const [loginSuccess, setLoginSuccess] = useState(false);
    const [showVerificationRequiredPage, setShowVerificationRequiredPage] = useState(false);
    const [verificationEmailSent, setVerificationEmailSent] = useState(false);
    const [isResending, setIsResending] = useState(false);
    const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
    const { nameError, confirmPasswordError, validateName, validatePassword, validateConfirmPassword, isFormValid, isFormValid2, emailError, validateEmail, hasLength, hasLowerCase, hasUpperCase, hasDigit, hasSpecialChar } = useFieldValidation();
    const [isLoading0, setIsLoading0] = useState(true);
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [isLoadingRecaptcha, setIsLoadingRecaptcha] = useState(false);
    const [hovered, setHovered] = useState(false);
    const [activeModal, setActiveModal] = useState('');


    useEffect(() => {

    }, [loginSuccess]);

    // Function to open specific modals
    const handleOpenModal = (modalName) => {
        // Prevent default navigation behavior
        setActiveModal(modalName);
    };

    // Close modal function
    const closeModal = () => setActiveModal('');

    useEffect(() => {
        if (verificationCheckIntervalId) {
            setSignupSuccess('Verification email sent. Please check your email to verify your account. This may take a few minutes.');
        } else {
            setSignupSuccess('');
        }

        return () => {
            if (verificationCheckIntervalId) {
                clearInterval(verificationCheckIntervalId);
            }
        };
    }, [verificationCheckIntervalId]);


    const handleBlur = (e) => {
        validateEmail(e.target.value);
    };

    const preloadContent = useCallback(async () => {
        const imageLoadedPromise = new Promise((resolve, reject) => {
            const image = new Image();
            image.src = `${process.env.PUBLIC_URL}/logo.png`;
            image.onload = () => resolve(true);
            image.onerror = () => reject(new Error('Image loading failed'));
        });

        try {
            await imageLoadedPromise;
            setIsLoading(false);
        } catch (error) {
            console.error("Preloading error:", error);
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        preloadContent();
    }, [preloadContent]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading0(false);
        }, 3000); // Show the preloader for 3 seconds

        return () => clearTimeout(timer); // Clean up the timer
    }, []);


    if (isLoading) {
        return (
            <div className="flex items-center justify-center h-screen">
                <img src={`${process.env.PUBLIC_URL}/preloader.gif`} alt="Loading..." />
            </div>
        );
    }


    const LoadingOverlay = () => (
        <div className="preloader">
            <img src="/fishbone.png" className="animated-fist" alt="Animated Fist" />
            <div>Loading...</div>
        </div>
    );
    const handleResendVerification = async () => {
        // Get the verification count document for the user
        const verificationCountDoc = await getDoc(doc(db, "verificationCount", auth.currentUser.uid));

        if (verificationCountDoc.exists()) {
            const verificationCountData = verificationCountDoc.data();

            // Check if the count is more than 10 within the last 24 hours
            const oneDayAgo = Date.now() - 24 * 60 * 60 * 1000;
            if (verificationCountData.count > 10 && verificationCountData.timestamp.toMillis() > oneDayAgo) {
                // Too many verification emails sent, tell the user to wait
                const timeToWait = 24 - Math.floor((Date.now() - verificationCountData.timestamp.toMillis()) / (60 * 60 * 1000));
                alert(`Too many verification emails have been sent. Please wait ${timeToWait} hours before requesting another.`);
            } else {
                // Less than 10 verification emails sent, proceed with sending another
                await sendVerification(auth.currentUser);
            }
        } else {
            // No verification count document found, proceed with sending the verification email
            await sendVerification(auth.currentUser);
        }
    };
    const handleForgotPassword = () => {
        setShowForgotPasswordModal(true);
    };

    // Example in a React component
    const verifyRecaptcha = async (recaptchaToken) => {
        const response = await fetch('/api/verifyRecaptcha', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ recaptchaToken }),
        });

        if (!response.ok) {
            // It's a good practice to include more information about the failure
            throw new Error(`ReCAPTCHA verification failed: ${response.statusText}`);
        }

        const data = await response.json();
        return data; // Return the data object to the caller
    };


    const handleSubmit = async (action) => {
        setIsLoadingRecaptcha(true);
        let recaptchaToken = null;

        if (executeRecaptcha) {
            try {
                recaptchaToken = await executeRecaptcha(action);
                
                // Verify the reCAPTCHA token before proceeding
                const verificationResponse = await verifyRecaptcha(recaptchaToken); // Await the promise
                // Check for success directly on the response now
                if (!verificationResponse.success) {
                    console.error("ReCAPTCHA verification failed");
                    setIsLoadingRecaptcha(false);
                    return; // Stop the process if verification fails
                }
            } catch (error) {
                console.error("Recaptcha execute or verification error:", error);
                setIsLoadingRecaptcha(false);
                // Optionally, update the UI to inform the user
                return;
            }
        } else {
            console.log('Execute recaptcha not yet available');
            setIsLoadingRecaptcha(false);
            return;
        }

        // Proceed with login or signup logic
        try {
            if (action === 'login') {
                const userCredential = await logIn(email, password);

                if (userCredential.user.emailVerified) {
                    setRecaptchaToken(recaptchaToken); // Consider if this is still needed
                    setLoginSuccess(true);

                } else {
                    setShowVerificationRequiredPage(true);
                }

            } else if (action === 'signup') {
                const userCredential = await signUp(email, password);
                await sendVerification(userCredential.user);
                setupVerificationCheck();
            }
        } catch (error) {
            console.error('Error during process:', error);
            if (action === 'login') {
                setLoginError('The email or password is incorrect.');
            }
            // Handle other cases as needed
        } finally {
            setIsLoadingRecaptcha(false);
        }
    };





    function setupVerificationCheck() {
        const intervalId = setInterval(async () => {
            await auth.currentUser.reload();
            if (auth.currentUser.emailVerified) {
                clearInterval(intervalId);
                setVerificationCheckIntervalId(null);
                setSignupSuccess(true); // Confirm email verification success

                // Call your API
                const response = await fetch('/api/moveUser', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        uid: auth.currentUser.uid,
                    }),
                });

                if (!response.ok) {
                    console.error('API call failed:', response);
                } else {
    
                    // Automatically log in the user
                    onLogin(auth.currentUser);
                }
            }
        }, 10000);

        setVerificationCheckIntervalId(intervalId);

        setTimeout(() => {
            clearInterval(intervalId);
            setVerificationCheckIntervalId(null);
        }, 300000);
    }


    function ReverificationModal({ isOpen, onConfirm, onCancel, signuperror1 }) {
        if (!isOpen) return null;

        return (
            <>
                <div className="fixed inset-0 bg-black bg-opacity-50" style={{ zIndex: 100 }}></div>
                <div className="bg-white p-4 rounded-lg shadow-md absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center w-1/2 min-h-0 max-h-full overflow-auto" style={{ zIndex: 200, width: '50vw' }}>
                    <p className="mt-8 font-semibold">{signuperror1}</p>
                    <button onClick={onConfirm} className="bg-green-600 hover:bg-green-700 active:bg-green-800 text-white px-4 py-2 mt-8 mb-8 rounded transition transform active:scale-95">OK</button>
                </div>
            </>
        );
    }
    function VerificationRequiredModal({ isOpen, isResending, verificationEmailSent, onResend, onCancel }) {
        if (!isOpen) return null;
        return (
            <>
                <div className="fixed inset-0 bg-black bg-opacity-50" style={{ zIndex: 100 }}></div>
                <div className="bg-white p-4 rounded-lg shadow-md absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center w-1/2 min-h-0 max-h-full overflow-auto" style={{ zIndex: 200, width: '50vw' }}>
                    <p className="mt-8 font-semibold">{verificationEmailSent ? "Verification email sent. Please check your email." : "Your email is not verified. Please verify your email to continue."}</p>
                    {!verificationEmailSent && (
                        <button onClick={onResend} disabled={isResending} className="bg-green-600 hover:bg-green-700 active:bg-green-800 text-white px-4 py-2 mt-8 mb-8 mr-2 rounded transition transform active:scale-95">Resend Verification Email</button>
                    )}
                    <button onClick={onCancel} disabled={isResending} className="bg-gray-800 hover:bg-gray-700 active:bg-gray-900 text-white px-4 py-2 rounded mt-8 mb-8 transition transform active:scale-95">Close</button>
                </div>
            </>
        );
    }
    function ForgotPasswordModal({ isOpen, onReset, onCancel }) {
        const [email, setEmail] = useState('');
        if (!isOpen) return null;
        return (
            <>
                <div className="fixed inset-0 bg-black opacity-50" style={{ zIndex: 100 }}></div>
                <div className="bg-white p-4 rounded-lg shadow-md absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center w-1/2 min-h-0 max-h-full overflow-auto" style={{ zIndex: 200, width: '50vw' }}>
                    <p className="mt-8 font-semibold">Please enter your email to reset your password.</p>
                    <input type="email" value={email} onChange={e => setEmail(e.target.value)} className="mt-8 mb-8 px-4 py-2 rounded border border-gray-300" />
                    <button onClick={() => onReset(email)} disabled={!email} className="bg-green-600 hover:bg-green-700 active:bg-green-800 text-white px-4 py-2 rounded transition transform active:scale-95">Reset Password</button>
                    <button onClick={onCancel} className="absolute top-0 right-0 bg-gray-800 text-white px-4 py-2 rounded transition transform active:scale-95">
                        X
                    </button>
                </div>
            </>
        );
    }



    if (isLoading0) {
        return (
            <div className="preloader">
                <img src={`${process.env.PUBLIC_URL}/fishbone.png`} className="animated-fist" alt="Animated Fist" />
            </div>
        );
    }
    return (
        <div className="App h-screen flex flex-wrap overflow-hidden AppBackground">
            {/* Header */}
            <header className="w-full fixed top-0 z-30 headfoot shadow-md">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex justify-between h-16 items-center">
                        <div className="flex items-center">
                            <img className="h-8 w-8" src={`${process.env.PUBLIC_URL}/logowhite.svg`} alt="Logo" />
                            <span className="ml-3 text-gray-100 text-lg font-semibold">RCA AI</span>
                        </div>
                        <nav className="hidden md:block">
                            <ul className="flex space-x-4">
                                <li><a onClick={() => handleOpenModal('About')} className="px-3 py-2 rounded-md text-sm font-medium text-gray-100 hover:text-gray-900 hover:bg-gray-100" href="#about">About</a></li>
                                <li><a onClick={() => handleOpenModal('Resources')} className="px-3 py-2 rounded-md text-sm font-medium text-gray-100 hover:text-gray-900 hover:bg-gray-100" href="#resources">Resources</a></li>
                                <li><a onClick={() => handleOpenModal('The RCA AI Project')} className="px-3 py-2 rounded-md text-sm font-medium text-gray-100 hover:text-gray-900 hover:bg-gray-100" href="#rca-project">The RCA AI Project</a></li>
                            </ul>
                        </nav>
                        {/* Mobile Menu Button */}
                        <button onClick={() => handleOpenModal('MobileMenu')} className="md:hidden text-gray-100">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                            </svg>
                        </button>
                    </div>
                </div>
            </header>

            {/* Main Content */}
            <main className="flex-1 z-10 App h-screen flex flex-wrap overflow-hidden AppBackground">
                {/* Content */}
                <div className="fixed inset-0 flex items-center justify-center" style={{ zIndex: 0 }}>
                    <img
                        className="w-2/5 max-w-2/5 h-auto opacity-15"
                        src={`${process.env.PUBLIC_URL}/logowhite.svg`} // Changed from .png to .svg
                        alt="Logo"
                    />
                </div>
                {/* Video container */}
                <div className="w-full md:w-1/2 flex justify-center items-center p-4">
                    <div className="w-full h-full flex justify-center items-center relative">
                        <img
                            className="w-3/5 max-w-3/5 h-auto rounded-animation"
                            style={{
                                boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
                                zIndex: 1,
                            }}
                            src={`${process.env.PUBLIC_URL}/RCA_agents2.png`}
                            alt="Logo"
                            onMouseEnter={() => setHovered(true)}
                            onMouseLeave={() => setHovered(false)}
                        />
                    </div>
                </div>
                {/* Form container */}
                <div className="w-full md:w-1/2 flex flex-col justify-center items-center p-4 z-10 ">
                    <div className="w-full">
                        <div className="flex gap-4 justify-center mb-4">
                            <button onClick={() => setShowForm('login')} className={`w-28 h-12 bg-gray-800 ${showForm === 'login' ? 'bg-green-700' : 'hover:bg-green-400'} active:bg-green-500 text-white rounded-full radiate transition transform active:scale-95`}>Login</button>
                            <button onClick={() => setShowForm('signup')} className={`w-28 h-12 bg-gray-800 ${showForm === 'signup' ? 'bg-green-700' : 'hover:bg-green-400'} active:bg-green-500 text-white rounded-full radiate transition transform active:scale-95`}>Sign Up</button>
                        </div>
                        {showForm === 'login' && (
                            <form onSubmit={(e) => { e.preventDefault(); handleSubmit('login'); }} className="bg-white p-4 rounded-lg shadow-md loginformbg" style={{ zIndex: 10 }}>
                                {recaptchaError && <div className="error">{recaptchaError}</div>}
                                <input type="email" placeholder="Email" autoComplete="current-email" className="mb-4 p-2 border rounded w-full bg-gray-100" value={email} onChange={handleEmailChange} />
                                <input type="password" placeholder="Password" autoComplete="current-password" className="mb-4 p-2 border rounded w-full bg-gray-100" value={password} onChange={handlePasswordChange} />
                                {loginError && <div className="text-red-500 text-sm mb-2">{loginError}</div>}
                                <div className="flex justify-between items-center">
                                    <button type="submit" className="bg-green-600 hover:bg-green-700 active:bg-green-800 text-white px-4 py-2 rounded transition transform active:scale-95">Login</button>
                                    <a onClick={handleForgotPassword} className="text-blue-200 cursor-pointer text-sm">Forgot Password</a>
                                </div>
                            </form>
                        )}
                        {showForm === 'signup' && (
                            <form onSubmit={(e) => { e.preventDefault(); handleSubmit('signup'); }} className="bg-white p-4 rounded-lg shadow-md loginformbg" style={{ zIndex: 10 }}>

                                <input
                                    type="text"
                                    placeholder="Name"
                                    className="mb-1 p-2 border rounded w-full bg-gray-100"
                                    value={name}
                                    onChange={(e) => {
                                        setName(e.target.value);
                                        validateName(e.target.value);
                                    }}
                                />
                                {nameError && <div className="text-gray-100 text-sm mb-1">{nameError}</div>}
                                <input
                                    type="email"
                                    placeholder="Email"
                                    className="mb-1 mt-2 p-2 border rounded w-full bg-gray-100"
                                    value={email}
                                    onChange={handleEmailChange}
                                    onBlur={handleBlur}
                                />
                                {emailError && <div className="text-gray-100 text-sm mb-1">{emailError}</div>}
                                <input type="password" placeholder="Password" className="mb-1 mt-2 p-2 border rounded w-full bg-gray-100" value={password} onChange={(e) => { setPassword(e.target.value); validatePassword(e.target.value); }} />
                                <ul className="text-gray-100 text-sm mb-1">
                                    <li className={hasLength ? 'ticked' : 'crossed'}>At least 6 characters</li>
                                    <li className={hasLowerCase ? 'ticked' : 'crossed'}>At least one lowercase letter</li>
                                    <li className={hasUpperCase ? 'ticked' : 'crossed'}>At least one uppercase letter</li>
                                    <li className={hasDigit ? 'ticked' : 'crossed'}>At least one digit</li>
                                    <li className={hasSpecialChar ? 'ticked' : 'crossed'}>At least one special character</li>
                                </ul>
                                <input type="password" placeholder="Confirm Password" autoComplete="new-password" className="mb-4 p-2 border rounded w-full bg-gray-100" value={confirmPassword} onChange={(e) => { setConfirmPassword(e.target.value); validateConfirmPassword(password, e.target.value); }} />
                                {confirmPasswordError && <p className="text-red-500 text-xs mb-1" autoComplete="new-password">{confirmPasswordError}</p>}
                                <div className="flex justify-center">
                                    <button
                                        type="submit"
                                        disabled={!isFormValid()}
                                        className={`text-white px-4 py-2 rounded transition transform ${isFormValid() ? "active:scale-95 bg-green-600 hover:bg-green-700 active:bg-green-800" : "bg-gray-400 cursor-not-allowed"}`}
                                    >
                                        Sign Up
                                    </button>
                                </div>
                            </form>
                        )}
                    </div>
                </div>
                {/* Sticky footer for inquiries */}
                <div className="w-full text-center py-3 loginformbg text-white fixed bottom-0 z-20">For inquiries, <a href="https://www.linkedin.com/in/drmasad/" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline" style={{ zIndex: 50 }}>Dr M As'ad</a></div>
            </main>
            {/* Additional Component */}
            {isLoadingRecaptcha && <LoadingOverlay />}
            <ReverificationModal
                isOpen={!!loginError}
                signuperror1={loginError}
                onConfirm={() => {
                    setLoginError(''); // Clear the error message
                }}
            />
            <VerificationRequiredModal
                isOpen={showVerificationRequiredPage}
                isResending={isResending}
                verificationEmailSent={verificationEmailSent}
                onResend={async () => {
                    setIsResending(true); // Set isResending to true
                    // Call resendVerification to resend the verification email
                    await resendVerification();
                    setVerificationEmailSent(true); // Set verificationEmailSent to true
                    setIsResending(false); // Set isResending to false
                }}
                onCancel={() => {
                    setShowVerificationRequiredPage(false); // Hide modal on cancel
                    setVerificationEmailSent(false); // Reset verificationEmailSent
                }}
            />
            <ForgotPasswordModal
                isOpen={showForgotPasswordModal}
                onReset={async (email) => {
                    try {
                        await sendPasswordResetEmail(email);
                        alert('Password reset email sent. Please check your email.');
                        setShowForgotPasswordModal(false);
                    } catch (error) {
                        console.error(error); // Log the error object
                        alert('Error sending password reset email. Please try again.');
                    }
                }}
                onCancel={() => setShowForgotPasswordModal(false)}
            />
            {signupSuccess && (
    <>
        <div className="fixed inset-0 bg-black bg-opacity-50" style={{ zIndex: 100 }}></div>
        <div className="headfoot p-4 rounded-lg shadow-md absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center w-1/2 bg-white bg-opacity-50" style={{ zIndex: 200 }}>
            <p className="text-white">{signupSuccess}</p>
            <p className="text-white font-bold animate-pulse">Verification Pending<span className="animate-bounce">...</span></p>
        </div>
    </>
)}
            {/* Modal Implementation */}
            {activeModal === 'About' && (
                <div className="fixed inset-0 bg-gray-800 bg-opacity-90 flex items-center justify-center overflow-auto" style={{ zIndex: 2000 }}>
                    {/* Modal Content */}
                    <div className="headfoot p-4 text-gray-100 overflow-auto relative rounded-lg"
                        style={{
                            maxHeight: '90vh',
                            maxWidth: '90vw',
                            width: '100%',
                            margin: 'auto',
                        }}>
                        <div className="sticky top-0 headfoot bg-opacity-90 z-10 flex justify-between items-center px-4 mb-2">
                            <h2 className="text-2xl font-bold mb-4">About</h2>
                            <button onClick={closeModal} className="text-4xl text-gray-100">×</button>
                        </div>
                        <p>I'm an <b>Emergency Room (ER) specialist</b> fueled by a deep passion for always getting better and finding innovative solutions that can shape the future of healthcare. I've got a knack for visualizing ideas and making them happen. My goal? Staying ahead of the game and embracing the newest trends and technologies that could completely transform patient care.</p>
                        <br />
                        <p>My professional interests are a blend of <b>healthcare quality, health information systems (HIS), and the latest tech breakthroughs</b>. I'm always on the lookout for fresh ways to make healthcare delivery more efficient and effective. Whether it's by simplifying processes, introducing cutting-edge tools, or using data-driven insights, I'm all about making things better.</p>
                        <br />
                        <p>My mission is to connect the clinical world with the realm of technology, using innovation to make real, meaningful changes. By teaming up with diverse groups and stakeholders, I aim to develop practical, user-friendly solutions that tackle the toughest challenges faced by healthcare pros and patients alike.</p>
                        <br />
                        <p>I'm big on <b>planning and execution</b>. Every project I take on gets my full attention to detail, and I'm all about delivering concrete results. I truly believe in the power of well-thought-out systems and processes, and I'm dedicated to creating solutions that not only make an impact but also last.</p>
                        <br />
                        <p>As I journey through the ever-changing world of healthcare, my motivation stems from a genuine desire to make a positive impact on patients' lives and the work of my colleagues. With my expertise, creativity, and unwavering commitment, I want to help build a future where healthcare is accessible, efficient, and effective for everyone.</p>

                    </div>
                </div>
            )}
            {/* Modal Implementation */}
            {activeModal === 'Resources' && (
                <div className="fixed inset-0 bg-gray-800 bg-opacity-90 flex items-center justify-center" style={{ zIndex: 2000 }}>
                    {/* Modal Content */}
                    <div className="headfoot p-4 w-11/12 h-9/10 text-gray-100 overflow-auto relative rounded-lg">
                        <button onClick={closeModal} className="absolute top-0 right-0 text-4xl text-gray-100 p-2">×</button>
                        <h2 className="text-2xl font-bold">Resources</h2>
                        <p>This is being build if you see this.</p>
                    </div>
                </div>
            )}
            {/* Modal Implementation */}
            {activeModal === 'The RCA AI Project' && (
                <div className="fixed inset-0 bg-gray-800 bg-opacity-90 flex items-center justify-center overflow-auto" style={{ zIndex: 2000 }}>
                    {/* Modal Content */}
                    <div className="headfoot p-4 text-gray-100 overflow-auto relative rounded-lg"
                        style={{
                            maxHeight: '90vh',
                            maxWidth: '90vw',
                            width: '100%',
                            margin: 'auto',
                        }}>
                        <div className="sticky top-0 headfoot bg-opacity-90 z-10 flex justify-between items-center px-4 mb-2">
                            <h2 className="text-2xl font-bold mb-4">The RCA AI: Prerelease v0.1</h2>
                            <button onClick={closeModal} className="text-4xl text-gray-100">×</button>
                        </div>
                        <p className="mb-4">Harnessing the Power of AI for Enhanced Root Cause Analysis in Healthcare</p>
                        <p className="mb-4">We are excited to announce the prerelease of our groundbreaking RCA AI Web Portal, a cutting-edge platform designed to revolutionize the way healthcare professionals, both clinical and non-clinical, approach root cause analysis (RCA) of patient safety incidents. This innovative tool leverages the power of artificial intelligence to provide valuable insights and support for those involved in the critical process of identifying and addressing the underlying causes of adverse events.</p>
                        <h3 className="text-xl font-bold mb-2">Key Features and Benefits:</h3>
                        <ol className="list-decimal pl-6 mb-4">
                            <li className="mb-2">AI-Powered Assistance: Our web portal employs sophisticated AI agents that work alongside healthcare staff, offering intelligent guidance and recommendations throughout the RCA process. These agents draw upon vast knowledge bases and advanced algorithms to provide targeted support tailored to each unique case.</li>
                            <li className="mb-2">Enhanced User Experience: We are continuously developing and refining tools to enhance the user experience within the RCA AI Web Portal. One such tool is the Ishikawa diagram, a visual representation that helps users organize and analyze the various factors contributing to an incident. This intuitive interface facilitates a more comprehensive understanding of the complex relationships between causes and effects.</li>
                            <li className="mb-2">Educational Value: The RCA AI Web Portal serves as an invaluable educational resource for healthcare professionals. By engaging with the AI agents and exploring real-world RCA cases, users can expand their knowledge, sharpen their critical thinking skills, and gain practical experience in identifying and mitigating patient safety risks. This innovative approach represents the first known application of AI technology for educational purposes in the field of root cause analysis.</li>
                            <li className="mb-2">Targeted Access: During this prerelease phase, access to the RCA AI Web Portal is limited to select healthcare institutions. This targeted approach allows us to collaborate closely with these institutions, gather valuable feedback, and refine the platform to best meet the needs of the healthcare community.</li>
                            <li>Continuous Improvement: We acknowledge that, as with any AI-generated content, there may be occasional inaccuracies or mistakes. However, our dedicated team is committed to ongoing development and refinement of the AI agents to ensure the highest levels of accuracy and reliability. We value user feedback and will actively incorporate it to enhance the platform's performance and utility.</li>
                        </ol>
                        <p className="mb-4">The RCA AI Web Portal represents a significant step forward in leveraging technology to improve patient safety and quality of care. By providing healthcare professionals with powerful AI-driven tools and educational opportunities, we aim to foster a culture of continuous learning, collaboration, and proactive risk management. We invite you to explore the potential of this innovative platform and join us in reshaping the future of root cause analysis in healthcare.</p>
                        <p>Please note that the RCA AI Web Portal is currently in a prerelease phase and is subject to ongoing updates and enhancements. We appreciate your understanding and look forward to your valuable feedback as we work together to revolutionize the practice of root cause analysis.</p>
                    </div>
                </div>
            )}
            {/* Mobile Navigation Menu Modal Implementation */}
            {activeModal === 'MobileMenu' && (
                <div className="fixed inset-0 bg-gray-800 bg-opacity-90 flex items-start justify-end" style={{ zIndex: 1000 }}>
                    {/* Modal Content */}
                    <div className={`headfoot p-4 w-4/5 h-full text-gray-100 overflow-auto relative transition-transform duration-200 ease-in-out ${activeModal === 'MobileMenu' ? 'transform translate-x-0' : 'transform translate-x-full'}`} style={{ maxWidth: '300px' }}>
                        <button onClick={closeModal} className="absolute top-0 left-0 text-4xl text-gray-100 p-2">×</button>
                        <ul className="divide-y divide-white mt-8">
                            <li className="py-4"><a onClick={() => handleOpenModal('About')} className="text-gray-100 hover:text-gray-900 hover:bg-gray-100" href="#about">About</a></li>
                            <li className="py-4"><a onClick={() => handleOpenModal('Resources')} className="text-gray-100 hover:text-gray-900 hover:bg-gray-100" href="#resources">Resources</a></li>
                            <li className="py-4"><a onClick={() => handleOpenModal('The RCA AI Project')} className="text-gray-100 hover:text-gray-900 hover:bg-gray-100" href="#rca-project">The RCA AI Project</a></li>
                        </ul>
                    </div>
                </div>
            )}
        </div>
    );
}
export default LandingPage;